<template>
    <div id="comment" class="mb-4">
        <div class="comment-box">
            <div class="commentDetail mt-2">
                <div :class="this.$route.name !== 'Details' ? 'd-flex commentDetailLink' :'custom-detail-badge commentDetailLink'">
                    <router-link v-if="this.$route.name !== 'Details'" :to="articleDetail(comment.article)"
                        class="larger-link mr-2 fw-bold ml-2 username user-link mb-0"
                        style="font-size: 11px;text-decoration: underline !important;color: gray;">{{ comment.article.title }}
                    </router-link>
                    <router-link v-if="this.$route.name !== 'Details'" :to="articleDetail(comment.article)"
                        class="small-link d-md-none mr-2 fw-bold ml-2 username user-link mb-0"
                        style="font-size: 11px;text-decoration: underline !important;color: gray;">{{ comment.article.title.slice(0,16)
                        }}
                    </router-link>
                
                    <label class="top-text mobile-badge  mb-0 small-badge d-md-none">
                        <img v-if="updateImageCustomTag(comment.comment_tag)" alt="tag-img"
                            :src="updateImageCustomTag(comment.comment_tag)" height="20" width="22" class="mr-1"
                            style="border: 1px solid white; object-fit: cover;">
                        <span :style="updateBadgeCustomTag(comment.comment_tag)"
                            :class="'text-capitalize '+updateBadgeClass(comment.comment_tag)">{{comment.comment_tag}}</span>
                    </label>
                </div>
                <div :class="this.$route.name !== 'Details' ? 'd-flex' :'d-flex '" >
                    <div class="img">
                        <img :src="comment.user.profile_pic" class="rounded-circle comment-detail-img"
                             alt="user-profile"/>
                    </div>
                    <div class="details w-100">
                        <div  class="d-flex justify-content-between" style="line-height:15px!important">
                            <div>
                                <span class="fw-bold ml-2 username user-profile-name" :style="dynamicStyle">{{comment.user.name }}</span>
                                <span class="dot comment-dot mr-1 ml-1"></span>
                                <label class="top-text comment-date mb-0 ml-xxl-0 ml-lg-0 ml-sm-0">{{ comment.created_at }}</label>
                            </div>
                            <label class="top-text mobile-badge float-right mb-0 large-badge">
                                <img v-if="updateImageCustomTag(comment.comment_tag)" alt="tag-img" :src="updateImageCustomTag(comment.comment_tag)" height="20" width="24" class="mr-1" style="border: 1px solid white; object-fit: cover;">
                                <span :style="updateBadgeCustomTag(comment.comment_tag)" :class="'text-capitalize '+updateBadgeClass(comment.comment_tag)">{{comment.comment_tag}}</span>
                            </label>
                        </div>
                        <div class="read-more mb-12 d-block" style="--line-clamp: 3">
                            <input
                                    :id="'read-more-checkbox' + comment.id"
                                    type="checkbox"
                                    class="read-more__checkbox"
                                    aria-hidden="true"
                                    name="status"/>

                            <p class="read-more__text mb-2 right-text ml-2" style="margin-top:-3px">
                                {{ comment.comment }}
                            </p>

                            <label
                                    v-if="comment.comment.length > 300"
                                    :for="'read-more-checkbox' + comment.id"
                                    class="read-more__label ml-3"
                                    data-read-more="Read more"
                                    data-read-less="See less"
                                    aria-hidden="true"
                            ></label>
                        </div>
                        <div class="col like_row pt-2">
                            <svg
                                    v-if="isLogin"
                                    @click="addLikeOrDislike(true, comment.is_like_dislike)"
                                    class="cursor mr-1 like-svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M12 4 3 15h6v5h6v-5h6z"
                                        :class="'icon_svg-fill icon_svg-stroke-' + isLikeColor(comment.is_like_dislike)"
                                        stroke-width="1.5"
                                        stroke="#666"
                                        fill="none"
                                        stroke-linejoin="round">
                                </path>
                            </svg>
                            <svg
                                    v-if="!isLogin"
                                    data-toggle="modal"
                                    data-target="#LoginModal"
                                    class="cursor ml-1"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                        d="M12 4 3 15h6v5h6v-5h6z"
                                        :class="'icon_svg-fill icon_svg-stroke-' +isLikeColor(comment.is_like_dislike) "
                                        stroke-width="1.5"
                                        stroke="#666"
                                        fill="none"
                                        stroke-linejoin="round">
                                </path>
                            </svg>
                            <label class="thumb mb-0">{{ comment.total_like }}</label>

                            <svg
                                    v-if="isLogin"
                                    @click="addLikeOrDislike(false, comment.is_like_dislike)"
                                    class="cursor ml-1 mr-1"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="m12 20 9-11h-6V4H9v5H3z"
                                        :class="'icon_svg-fill icon_svg-stroke-' +isDislikeColor(comment.is_like_dislike)"
                                        stroke="#666"
                                        fill="none"
                                        stroke-width="1.5"
                                        stroke-linejoin="round">
                                </path>
                            </svg>

                            <svg
                                    v-if="!isLogin"
                                    data-toggle="modal"
                                    data-target="#LoginModal"
                                    class="cursor ml-1 mr-1"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="m12 20 9-11h-6V4H9v5H3z"
                                        :class="'icon_svg-fill icon_svg-stroke-' +isDislikeColor(comment.is_like_dislike)"
                                        stroke="#666"
                                        fill="none"
                                        stroke-width="1.5"
                                        stroke-linejoin="round">
                                </path>
                            </svg>
                            <label class="thumb mb-0">{{ comment.total_dislike }}</label>
                            <label
                                    class="ml- mr-2 likeComment"
                                    style="color: lightgray">
                            </label>
                            <label
                                    v-if="showWriterImage(comment.writer, comment.writerLike).isImage"
                                    class="ml- mr-2 likeComment"
                                    style="color: lightgray">
                                <img
                                        :src="showWriterImage(comment.writer, comment.writerLike).image"
                                        class="rounded-circle"
                                        width="20"
                                        height="20"
                                        alt="writer-image"/>
                                <i v-if="comment.writer" class="fa fa-message writerComment"></i>
                                <svg
                                        v-if="comment.writerLike"
                                        data-v-3417ebcc=""
                                        width="12"
                                        height="12"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="cursor mr-1 arrow"
                                        style="position: absolute; top: 20px; left: 97px">
                                    <path
                                            data-v-3417ebcc=""
                                            d="M12 4 3 15h6v5h6v-5h6z"
                                            stroke-width="1.5"
                                            stroke="#666"
                                            fill="none"
                                            stroke-linejoin="round"
                                            class="icon_svg-fill icon_svg-stroke-isLiked">
                                    </path>
                                </svg>
                            </label>
                            <label
                                    v-if="!isLogin"
                                    data-toggle="modal"
                                    data-target="#LoginModal"
                                    class="text-info text-muted font-weight-bold ml-3">REPLY</label>
                            <label
                                    v-if="isLogin"
                                    class="text-info text-muted font-weight-bold ml-3"
                                    @click="displayReply = !displayReply">REPLY</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="displayReply">
                <div class="row align-items-start">
                    <div class="col-md-1 col-sm-2 col-2 img">
                        <img
                                :src="getAuthUser.profile_pic"
                                class="rounded-circle"
                                width="40px"
                                height="40px"
                        />
                    </div>
                    <div class="col-md-11 col-10 col-sm-10 pl-0 details">
                        <form @submit.prevent="validateBeforeSubmit">
                            <div class="form-group mb-3 mb-md-0 mb-sm-1">
                                <input
                                        name="reply"
                                        v-model="reply"
                                        type="text"
                                        placeholder="Add Reply"
                                        autocomplete="off"
                                        class="form-control reply-comment-input"
                                />
                            </div>
                            <button
                                    :disabled="isValidateButton"
                                    type="submit"
                                    class="btn btn-outline-dark float-right reply-submit-button mt-2">REPLY
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!---view all reply------>
            <div class="main mt-3" v-if="showAllReply">
                <div
                        v-for="reply in comment.replies"
                        :key="reply.id + '_' + Math.random()"
                        class="d-flex justify-content-between mb-2 ml-4">
                    <div class="d-flex align-items-start">
                        <div class="img ml-3">
                            <img
                                    :src="reply.user.profile_pic"
                                    class="rounded-circle comment-detail-img"/>
                        </div>
                        <div class="details ml-2q">
                            <span class="fw-bold username reply-user-name pl-2">{{reply.user.name}}</span>
                            <span data-v-3417ebcc="" class="dot comment-dot mr-1 ml-1"></span>
                            <label class="topText reply-date">{{ reply.created_at }}</label>
                            <label class="d-block mb-0 comment pl-2">{{ reply.comment }}</label>
                        </div>
                        <div class="date"></div>
                    </div>
                </div>
            </div>
            <!----viw all reply toggle--->
            <span
                    v-if="comment.replies.length > 0"
                    @click="showAllReply = !showAllReply"
                    class="reply ml-5">
                <i
                    :class="showAllReply ? 'fa fa-caret-up' : 'fa fa-caret-down'"
                    aria-hidden="true">
                </i>
                <a class="view-replies text-dark mt-3 ml-2">
                    <u v-if="!showAllReply" @click="readComment()" style="color: #17a2b8" >View all replies ({{ comment.replies.length }})
                        <i
                            v-if="comment.check_is_read"
                            class="fa fa-envelope text-muted ml-2">
                        </i>
                    </u>
                    <u v-if="showAllReply" style="color: inherit">Hide all replies</u>
                </a>&nbsp;
            </span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "userSendComment",
        props: {
            comment: Object,
            // customTags : Array
        },
        data() {
            return {
                displayReply: false,
                showAllReply: false,
                reply: "",
                isValidate: false,
            };
        },
        components: {},
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: "auth/getAuthUser",
                customTags :"article/getCustomTags"
            }),
            isValidateButton() {
                return this.reply === "";
            },
            dynamicStyle() {
                if(this.comment.user.subscription_color){
                    return {'background-color': this.comment.user.subscription_color};
                }else{
                    return {};
                }
            }

        },

        methods: {
            emptyForm() {
                this.reply = "";
            },
            updateBadgeClass(commentTag) {
                if (commentTag === "supportive") {
                    return "badge-pill supportive";
                }
                if (commentTag === "neutral") {
                    return "badge-pill neutral";
                }
                if (commentTag === "critique") {
                    return "badge-pill critique";
                }
            },
            updateBadgeCustomTag(commentTag){
                if(this.customTags !== undefined) {
                    let index = this.customTags.findIndex(x => x.name === commentTag);
                    if (index !== -1) {
                        return 'border-radius: 10rem;color: #fff;padding: 3px 5px;font-size: 11px;background:' + this.customTags[index].color;
                    }
                }
            },
            updateImageCustomTag(commentTag){
                if(this.customTags !== undefined){
                    let index  = this.customTags.findIndex(x=>x.name === commentTag);
                    if(index !== -1){
                        return this.customTags[index].emoji;
                    }
                }
                return null;
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            isLikeColor(is_like_dislike) {
                if (is_like_dislike === null) {
                    return "like";
                }
                if (is_like_dislike) {
                    return "isLiked";
                }
                if (is_like_dislike === false) {
                    return "like";
                }
            },
            isDislikeColor(is_like_dislike) {
                if (is_like_dislike === null) {
                    return "dislike";
                }
                if (is_like_dislike === false) {
                    return "isDisliked";
                }
                if (is_like_dislike) {
                    return "dislike";
                }
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let payload = {
                            comment: this.reply,
                            is_reply: true,
                            article_id: this.comment.article.id,
                            self_parent_id: this.comment.id,
                        };
                        this.$store.dispatch("article/addComment", payload).then(() => {
                            this.emptyForm();
                            this.showAllReply = true;
                        });
                    }
                });
            },
            showWriterImage(writer, writerLike) {
                if (writer) {
                    return {image: writer.profile_pic, isImage: true};
                }
                if (writerLike) {
                    return {image: writerLike.profile_pic, isImage: true};
                }
                return {image: "", isImage: false};
            },
            addLikeOrDislike(isLike, isDelete) {
                if (isDelete === null) {
                    let payload = {
                        article_id: this.comment.article.id,
                        comment_id: this.comment.id,
                        is_like: isLike,
                    };
                    this.$store.dispatch("article/addLikeOrDislike", payload);
                }
                if (isLike === true && isDelete === true) {
                    let payload = {
                        article_id: this.comment.article.id,
                        comment_id: this.comment.id,
                    };
                    this.$store.dispatch("article/deleteLikeOrDislike", payload);
                }
                if (isLike === false && isDelete === false) {
                    let payload = {
                        article_id: this.comment.article.id,
                        comment_id: this.comment.id,
                    };
                    this.$store.dispatch("article/deleteLikeOrDislike", payload);
                }
                if (isLike === false && isDelete === true) {
                    let payload = {
                        article_id: this.comment.article.id,
                        comment_id: this.comment.id,
                        is_like: isLike,
                    };
                    this.$store.dispatch("article/addLikeOrDislike", payload);
                }
                if (isLike === true && isDelete === false) {
                    let payload = {
                        article_id: this.comment.article.id,
                        comment_id: this.comment.id,
                        is_like: isLike,
                    };
                    this.$store.dispatch("article/addLikeOrDislike", payload);
                }
            },
            articleDetail(article) {
                return {
                    name: "Details",
                    params: {slug: article.slug, articleId: article.id},
                };
            },
            readComment() {
                let payload = {
                    article_id: this.comment.article.id,
                    comment_id: this.comment.id,
                };
                this.$store.dispatch("article/readComments", payload);
            },
            subscriptionColor(){
                if(this.comment.user.subscription_color){
                    return "background-color:red; color:white; padding:2px 5px"
                }else{
                    return '';
                }
            },
        },
        watch: {},
    };
</script>

<style scoped>
.right-text {
        font-size: 14px;
        line-height: 1.5;
        font-family: "Roboto", "Arial", sans-serif;
    }

    span .likeDislikeWrapper {
        position: absolute;
        bottom: 11px !important;
        left: 116px;
        display: flex;
        -moz-column-gap: 3px;
        column-gap: 5px;
        color: gray;
    }

    span .likeDislikeWrapper i {
        font-size: 11px;
    }

    .img .comment-detail-img {
        width: 30px;
        height: 30px;
    }

    .field {
        width: 535px;
        border-radius: 5px;
    }

    .date .topText {
        font-size: 10px;
        color: gray;
        cursor: pointer;
        margin-right: 35px;
    }

    .reply {
        cursor: pointer;
    }

    .like_row {
        font-size: 13px;
    }

    .like_row .thumb {
        cursor: pointer;
    }

    /*.like_row .thumbs-icon {*/
    /*    color: #aaaaaac7;*/
    /*    cursor: pointer;*/
    /*}*/

    .isLiked:hover {
        color: #aaaaaac7;
        cursor: pointer;
    }

    .isLiked {
        color: #4267b2;
        cursor: pointer;
    }

    .isDisliked:hover {
        color: #aaaaaac7 !important;
        cursor: pointer;
    }

    .isDisliked {
        color: #dc3545 !important;
        cursor: pointer;
    }

    .like:hover {
        color: #4267b2;
        cursor: pointer;
    }

    .like {
        color: #aaaaaac7;
        cursor: pointer;
    }

    .dislike {
        color: #aaaaaac7;
        cursor: pointer;
    }

    .dislike:hover {
        color: #dc3545;
        cursor: pointer;
    }

    .text-muted {
        cursor: pointer;
        font-size: 12px;
    }

    .button {
        font-size: 14px;
        margin: 8px 0 0;
    }

    /* .button:hover {
            color: #eee;
            background-color: #17a2b8;
            border-color: #17a2b8;
        } */

    #all-reply {
        width: 66%;
        margin-left: 54px;
    }

    #like {
        width: 72%;
    }

    .view-replies {
        font-weight: bold;
        font-size: 13px;
        font-family: "Raleway", sans-serif;
        color: #17a2b8;
    }

    .view-replies:hover {
        color: #17a2b8;
    }

    .text {
        display: flex;
    }

    .text .username {
        font-size: 16px;
    }

    .replyImg {
        width: 80px;
    }

    .text .top-text {
        font-size: 11px;
    }

    .input {
        width: 553px;
    }

    .goodWill {
        background-color: #17a2b8;
        color: #fff;
    }

    .top-text .badge-pill {
        font-size: 11px;
    }

    .critique,
    .neutral,
    .supportive {
        color: #fff;
        padding: 3px 5px;
    }

    .critique {
        background-color: #dc3545;
    }

    .neutral {
        background-color: darkgray;
    }

    .supportive {
        background-color: green;
    }

    .commentDetail {
        flex: 0 0 75%;
    }

    .icon_svg-stroke-isLiked:hover {
        fill: rgb(129, 131, 135);
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-isLiked {
        fill: rgb(129, 131, 135);
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-like:hover {
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-like {
        stroke-width: 1.8;
    }

    .icon_svg-stroke-isDisliked:hover {
        fill: rgb(129, 131, 135);
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-isDisliked {
        fill: rgb(129, 131, 135);
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-dislike:hover {
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .icon_svg-stroke-dislike {
        stroke: rgb(129, 131, 135);
        stroke-width: 1.8;
    }

    .read-more {
        /* You can update this variable directly in the html by adding a style property to the .read-more element */
        --line-clamp: 3;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        word-break: break-all;
    }

    .read-more__text {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp);
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .read-more__checkbox {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .read-more__checkbox ~ .read-more__label {
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        color: #6c757d !important;
    }

    /* Don't forget focus and hover styles for accessibility! */

    .read-more__checkbox:hover ~ .read-more__label {
        text-decoration: none;
    }

    .read-more__checkbox ~ .read-more__label::before {
        content: attr(data-read-more);
    }

    .read-more__checkbox:checked ~ .read-more__label::before {
        content: attr(data-read-less);
    }

    .read-more__checkbox:checked ~ .read-more__text {
        --line-clamp: none;
        -webkit-line-clamp: var(--line-clamp);
    }

    .writerComment {
        position: absolute;
        top: 22px;
        left: 118px;
        color: grey;
        font-size: 9px;
    }

    .writer-vote {
        position: absolute;
        top: 22px;
        left: 105px;
    }

    .dot {
        height: 3px;
        width: 3px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .user-profile-name,
    .comment-date,
    .reply-user-name,
    .reply-date {
        font-size: 13px;
        color: grey;
        font-weight: 400;
        font-family: Roboto;
    }

    .details .comment {
        word-break: break-all;
    }
    .mobile-badge{
        float:right;
    }
    .commentDetail .commentDetailLink{
        margin-left: 30px;
        justify-content: space-between;
    }
</style>
