<template>
    <div id="heading_request">
        <div class="row mt-5" id="row" v-if="getAuthUser.is_heading === 0">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div>
                    <div v-if="articleDetail.status.id !==7">
                        <div v-if="isHeadingRequest" class="sahre-icons" style="display: flex;">
                            <a href="javascript:void(0)">
                                <label class="mb-0 shareText font-weight-bold"
                                       style="color:#2c3e50; font-size: 14px">Share:</label>
                            </a>
                            <span class="heading-icons">
                                <a @click="shareFacebookArticle(articleDetail)" class="d-inline-block"
                                   target="_blank">
                                    <img src="../assets/images/facebook.png" width="17" height="17" alt="no-image">
                                </a>
                                <a :href="shareTwitterArticle(articleDetail)" target="_blank" class=" d-inline-block">
                                    <img src="../assets/images/twitter.png" width="17" height="17" alt="no-image">
                                </a>
                                <a @click="copyClipboard(articleDetail)" target="_blank" class=" d-inline-block">
                                    <img src="../assets/images/share-link.png" width="17" height="17" alt="no-image">
                                </a>
                            </span>
                            <!-- <p class="mb-0">: {{setArticleUrl(articleDetail)}}</p>-->
                        </div>
                    </div>
                    <div class="d-flex" style="justify-content: space-between;">
                    <h5 class="upcoming-article">Upcoming Article Description</h5>
                    
                    </div>
                    <textarea @keyup="isApiSubmit = true" v-model="upcomingContent" id="upcomingContent"
                              class="form-control"  rows="5" name="upcoming_content" maxlength="1000"
                              spellcheck="false">
                    </textarea>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                <div v-if="articleDetail.status.id ===7">
                    <h6 class="text-center">Accepting Heading Requests</h6>
                    <div v-if="isHeadingRequest" class="sahre-icons" style="display: flex;justify-content: center">
                        <a href="javascript:void(0)">
                            <label class="mb-0 shareText font-weight-bold"
                                   style="color:#2c3e50; font-size: 14px">Share:</label>
                        </a>
                        <span class="heading-icons">
                                <a @click="shareFacebookArticle(articleDetail)" class=" d-inline-block  "
                                   target="_blank">
                                    <img src="../assets/images/facebook.png" width="17" height="17" alt="no-image">
                                </a>
                                <a :href="shareTwitterArticle(articleDetail)" target="_blank" class=" d-inline-block">
                                    <img src="../assets/images/twitter.png" width="17" height="17" alt="no-image">
                                </a>
                                <a @click="copyClipboard(articleDetail)" target="_blank" class=" d-inline-block">
                                    <img src="../assets/images/share-link.png" width="17" height="17" alt="no-image">
                                </a>
                            </span>
                    </div>
                </div>
                <div class="row" v-if="articleDetail.status.id !== 7 && articleDetail.article_category.length===0">
                <div class="col-sm-12 btn-box d-block" v-if="!isHeadingRequestSubmit">
                    <button :disabled="isDisabled" @click="headingRequestModel()"
                            class="btn float-right  mb-2 ml-2 acceptHeadingBtn" :style="cursorPosition">
                        Accept Heading Requests
                    </button>
                </div>
                <div class="col-sm-12  d-block" v-if="!isHeadingRequestSubmit">
                    <div class="d-flex pt-2 justify-content-end" style="column-gap: 20px;">
                        <div class="form-check">
                            <input v-model="isHeading" class="form-check-input" :value="0" type="radio" name="flexRadioDefault"
                                id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Headings
                            </label>
                        </div>
                        <div class="form-check">
                            <input v-model="isHeading" class="form-check-input" :value="1" type="radio" name="flexRadioDefault"
                                id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Heading Request and Body
                            </label>
                        </div>
                    </div>

                    <div v-if="getAuthUser.stripe_connect !==null" class="d-flex pt-2 justify-content-end" style="column-gap: 20px;">
                        <div class="form-check">
                            <input v-model="isPaid" class="form-check-input" :value="0" type="radio" name="flexRadio"
                                   id="flexRadio">
                            <label class="form-check-label" for="flexRadio">
                                Free
                            </label>
                        </div>
                        <div class="form-check">
                            <input v-model="isPaid" class="form-check-input" :value="1" type="radio" name="flexRadio"
                                   id="flexRadio2">
                            <label class="form-check-label" for="flexRadio2">
                                Members Only
                            </label>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </div>
        <div class="all-heading-request">
            <div class="row">
                <div class="col-sm-8 mt-5" v-if="getAllArticleSuggestion.length>0">
                    <div v-for="articleSuggestion in getAllArticleSuggestion"
                         :key="articleSuggestion.id+Math.random()">
                        <ArticleSuggestionContent v-if="isChecked(articleSuggestion)" :articleSuggestion="articleSuggestion" @emitUpdatedArticleSuggestionContent="emitUpdatedArticleSuggestionContent" />
                    </div>
                </div>
                <div class="col-sm-4 mt-5" v-if="getAllArticleSuggestion.length>0">
                    <h2 class="all-heading">All Heading Requests</h2>
                    <div v-for="articleSuggestion in getAllArticleSuggestion"
                         :key="articleSuggestion.id+Math.random()">
                        <ArticleSuggestion :articleSuggestion="articleSuggestion" :checked="isChecked(articleSuggestion)"
                                           @emitIsSelectedSuggestions="emitIsSelectedSuggestions"/>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-2 add-up-button">
                            <button @click="addHeadingRequest" v-if="articleDetail.status.id !== 7" type="button" class="btn btn-info btn-sm custom_btn">Add
                                Up to 10
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HeadingRequestCategoriesModal @emitSelectWorldCategories="emitSelectWorldCategories"
                                       @emitSelectCountryCategories="emitSelectCountryCategories"/>
    </div>
</template>

<script>
    import HeadingRequestCategoriesModal from "../components/Modals/HeadingRequestCategoriesModal";
    import ArticleSuggestion from "../components/HeadingRequest/ArticleSuggestion";
    import ArticleSuggestionContent from "../components/HeadingRequest/ArticleSuggestionContent";
    import $ from "jquery";
    import Helper from "../helper";
    // import helpers from "../helpers";
    import {mapGetters} from "vuex";
    import helpers from "../helpers";

    export default {
        name: 'headingRequest',
        components: {
            HeadingRequestCategoriesModal, ArticleSuggestion, ArticleSuggestionContent
        },
        data() {
            return {
                globe_categories: [],
                country_categories: [],
                isApiSubmit: false,
                suggestions: [],
                upcomingContent: "",
                isHeading:'',
                isPaid:'0',
                isSaveHeading:false,
                isHeadingSubmit:false,
            }
        },
        computed: {
            ...mapGetters(
                {
                    cardData: "article/getCardData",
                    selectedFlag: "country/selectedFlag",
                    defaultCountry: "country/defaultCountry",
                    articleDetail: 'article/getArticleDetails',
                    getAllArticleSuggestion: "article/getAllArticleSuggestion",
                    getUpcomingCount: "article/getUpcomingCount",
                    getAuthUser: 'auth/getAuthUser',
                }),
            isDisabled(){
                if(this.articleDetail.status){
                    return this.articleDetail.status.id===7
                }
                if(this.isSaveHeading){
                    return this.isSaveHeading===true;
                }
                return false
            },
            cursorPosition(){
                if(this.isSaveHeading === true){
                    return 'pointer-events: none'
                }
                if (this.articleDetail !== undefined) {
                    if (this.articleDetail.article_category !== undefined) {
                         if(this.articleDetail.article_category.length !== 0){
                             return 'pointer-events: none'
                         }
                    }
                    return false;
                }
                return false;
            },
            isHeadingRequest() {
                if (this.articleDetail !== undefined) {
                    if (this.articleDetail.article_category !== undefined) {
                        return this.articleDetail.article_category.length !== 0
                    }
                    return false;
                }
                return false;
            },
            isHeadingRequestSubmit(){
                if(this.isSaveHeading){
                    return this.isSaveHeading===true;
                }
                return false
            }
        },
        methods: {
            headingRequestModel() {
                if(this.isHeading === undefined || this.isHeading === null){
                    return Helper.toast(this, 'Alert', 'Please select Heading OR Heading & Body first', 'danger')
                }
                if(this.getAuthUser.stripe_connect && (this.isPaid === undefined || this.isPaid === null)){
                    return Helper.toast(this, 'Alert', 'Please select Free OR Member only first', 'danger')
                }
                if (this.cardData.title === "No title" || this.cardData.title === "") {
                    return Helper.toast(this, 'Alert', 'Please add title first', 'danger')
                } else {
                    if(this.upcomingContent  === ""){
                        return Helper.toast(this, 'Alert', 'Please add upcoming description first', 'danger')
                    }
                    if(this.getUpcomingCount>4){
                        return Helper.toast(this, 'Alert', 'You can publish maximum of 4 Upcoming Articles at one time. Please Publish or Archive previous Upcoming Articles in order to publish new Upcoming Articles', 'danger')
                    }
                    if (!this.isHeadingRequest) {
                        $('#headingRequestModel').modal('show');
                    }
                }
            },
            emitSelectWorldCategories(globeCategories) {
                globeCategories.map(x => {
                    this.globe_categories.push({id: parseInt(x.code), name: x.name})
                })
                this.submitRequest();
            },
            emitSelectCountryCategories(countryCategories) {
                countryCategories.map(x => {
                    this.country_categories.push({id: parseInt(x.code), name: x.name})
                })
                this.submitRequest();
            },
            shareFacebookArticle(article) {
                let url = this.$sharePath + article.slug
                /*global FB*/
                FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.likes',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': url,
                                'og:title': article.title,
                                'og:description': article.description,
                                'og:image': article.featured_image
                            }
                        })
                    },
                    function () {
                    });
            },
            shareTwitterArticle(article) {
                let url = this.$sharePath + article.slug
                return 'https://twitter.com/intent/tweet?text=' + url
            },
            copyClipboard(article) {
                if (article.slug === "") return;
                 const url=this.$sharePathUpcoming + article.slug
                navigator.clipboard.writeText(url);
                return Helper.toast(this, 'Copy', 'Article link copied', 'success')
            },
            submitRequest() {
                if(this.globe_categories.length >0 || this.country_categories.length >0){
                    this.isSaveHeading=true;
                }
                let payload = {
                    article_id: this.cardData.id !== '' ? parseInt(this.cardData.id) :  0,
                    globe_categories: this.globe_categories,
                    country_categories: this.country_categories,
                    country_id: parseInt(this.defaultCountry.id),
                    upcoming_content: this.upcomingContent,
                    is_heading:this.isHeading,
                    is_paid:this.isPaid,
                }
                this.$store.dispatch('article/createHeadingRequest', payload).then(() => {
                    this.isApiSubmit = false;
                    this.isHeadingSubmit=true;
                }).catch(error => {
                    this.isApiSubmit = false;
                    helpers.errorMessage(this, error);
                })
            },
            setArticleUrl(article) {
                if (article.slug === "") return;
                return this.$sharePathUpcoming + article.slug
            },
            getAllArticleSuggestionAccordingToArticle() {
                let payload = {article_id: this.cardData.id}
                this.$store.dispatch('article/getAllArticleSuggestionAccordingToArticle', payload)
            },
            emitIsSelectedSuggestions(suggestion) {
                let index = this.suggestions.findIndex(x => x.id === suggestion.id);
                if (index !== -1) {
                    this.suggestions[index] = suggestion
                } else {
                    this.suggestions.push(suggestion)
                }
            },
            isChecked(articleSuggestion) {
                if (articleSuggestion.selected_suggestion) {
                    return articleSuggestion.selected_suggestion.status === 1
                }
                return false;
            },
            addHeadingRequest() {
                let payload = {
                    article_id: this.cardData.id,
                    suggestions: this.suggestions
                }
                this.$store.dispatch('article/AddOrUpdateSelectedSuggestions', payload).then(response => {
                    helpers.successMessages(this, response);
                }).catch(error => {
                    helpers.errorMessage(this, error);
                })
            },
            emitUpdatedArticleSuggestionContent(articleSuggestionContent){
                let payload = {
                    article_id :articleSuggestionContent.articleSuggestion.article.id,
                    suggestion_id :articleSuggestionContent.articleSuggestion.id,
                    selected_suggestion_id :articleSuggestionContent.articleSuggestion.selected_suggestion.id,
                    content :   articleSuggestionContent.content
                }
                this.$store.dispatch('article/updatedArticleSuggestionContent',payload)
            },
            getUpcomingArticleCount(){
                this.$store.dispatch('article/getUpcomingCount')
            }
        },
        mounted() {
            if(this.cardData.upcoming_content){
                this.upcomingContent = this.cardData.upcoming_content;
            }
            if(this.cardData){
                this.isHeading = this.cardData.is_heading;
                this.isPaid = this.cardData.is_paid;
            }
            this.getAllArticleSuggestionAccordingToArticle();
            this.getUpcomingArticleCount()
        },
        watch: {
            isApiSubmit() {
                if (this.isApiSubmit) {
                    if(this.getUpcomingCount<4){
                        this.submitRequest();
                    }
                }
            }
        }
    }
</script>

<style scoped>
    #heading_request .upcoming-article {
        font-family: 'Merriweather';
        font-size: 19px;
        font-weight: 400;
        line-height: 2;
    }

    .text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 28px;
        font-family: "Oswald";
        font-weight: 400;
        margin-top: 0;
        line-height: 1.5;
        text-align: left;
    }

    .text .link {
        color: #007bff !important;
        font-size: 28px;
        font-family: "Oswald";
        font-weight: 400;
        text-decoration: none;
    }

    .acceptHeadingBtn {
        background: none;
        border: 2px solid;
        border-bottom-width: 4px;
        font: inherit;
        height: 40px;
        letter-spacing: inherit;
        padding: 0 30px;
        border-radius: 0;
        text-transform: inherit;
        -webkit-transition: color 1s;
        transition: color 1s;
        text-align: center;
        font-weight: 400;
    }

    .acceptHeadingBtn:hover {
        color: #006692;
        -webkit-animation: zigzag 1s linear infinite;
        animation: zigzag 1s linear infinite;
        background: linear-gradient(135deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(225deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(315deg, #dee2e67d 0.25em, transparent 0.25em) 0 0,
        linear-gradient(45deg, #dee2e67d 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.75em;
        -webkit-transition: all ease .2s;
        transition: all ease .2s;
        cursor: pointer;
    }

    .all-heading-request {
        margin-bottom: 150px;
    }

    @media (max-width: 767px) and (min-width: 587px) {
        #row {
            max-width: 1140px;
        }
    }

    @media (max-width: 991px) and (min-width: 768px) {
        .text {
            font-size: 15px;
            line-height: 1.5;
        }
    }

    @media (max-width: 1199px) and (min-width: 992px) {
        .text {
            font-size: 21px;
        }
    }
</style>
