<template>
    <div id="first">
        <div class="container scrollNav" :style="currentRouteName.includes('userProfile') || currentRouteName.includes('UpcomingDetail') || currentRouteName.includes('Details') ? ' background-color: transparent!important;backdrop-filter: blur(5px);' :'background-color:white'" id="nav-container">
            <header :class="isLogin ? 'header' : 'header not-logged-in'">
                <div
                        class="top-main d-flex justify-content-between align-items-center">
                    <div class="first-column">
                        <!--logo--->
                        <router-link to="/">
                            <img
                                    src="../assets/images/worded-logo.png"
                                    alt="logo"
                                    class="logo-icon d-md-block d-sm-none img-fluid"
                            />
                            <img
                                    src="../assets/images/logothumbnail.png"
                                    alt="logo"
                                    class="small-logo d-md-none d-sm-block"/>
                        </router-link>
                    </div>
                    <!-- search bar--->
                    <div class="searchBar second-column">
                        <!--<div class="callout" v-if="isToggle" v-click-outside="checkToggle"></div>-->
                        <div
                                @click="showFullSearch"
                                class="searchfield d-flex justify-content-center">
                            <SearchField/>
                        </div>
                    </div>
                    <!-----dots---->
                    <!--                    <div class="text-right third-column" v-if="!isLogin">-->
                    <!--                        <TopDots/>-->
                    <!--                    </div>-->
                    <!---login btn--->
                    <div class="header-right-side">
                        <div class="mobile-search-icon mr-3">
                            <div class="mb-search-icon-inner" @click="showFullSearch">
                                <i class="fas fa-search"></i>
                            </div>
                        </div>
                        <div v-click-outside="hide" class="inner-div d-flex justify-content-end align-items-center">
                            <router-link v-if="isLogin" @click.native.capture="addArticle" target="_top"
                                         :to="{ name: 'writeArticle' }">
                                <div class="edit-icon-mobile mr-3">
                                    <i class="fas fa-pencil d-md-none d-sm-block"></i>
                                </div>
                                <button class="btn write-button btn-write shadow-none d-md-block d-sm-none mr-3">
                                    Write
                                </button>
                            </router-link>
                            <div v-if="isLogin" class="profile-wrapper abc position-relative">
                                <img
                                        class="cursor"
                                        v-on:click="checkToggle"
                                        :src="getAuthUser.profile_pic"
                                         alt="profile"/>
                                <ul v-show="isToggle" class="custom-dropdown-menu">
                                    <RouterLink :to="{name:'userProfile',params:{slug:getAuthUser.slug}}"
                                                @click.native.capture="profile"
                                                target="_top" class="userName">
                                        <li @click="checkToggle">
                                            <h5>{{ getAuthUser.name }}</h5>
                                        </li>
                                    </RouterLink>
                                    <a href="/draft">
                                        <li @click="checkToggle">Drafts</li>
                                    </a>
                                    <RouterLink to="/heading/requests">
                                        <li @click="checkToggle">Heading Requests
                                            <i v-if="isNotify>0" id="bellIcon" class="fa fa-bell ml-2" aria-hidden="true"></i>
                                        </li>
                                    </RouterLink>
                                        <li v-if="getAuthUser.is_monetize && getAuthUser.stripe_connect !==null" @click="stripeAccountLogin" style="cursor: pointer;">Stripe Connect</li>
                                        <li v-if="getAuthUser.is_monetize && getAuthUser.stripe_connect===null" style="cursor: pointer;" @click="stripeConnect">Stripe Connect</li>
                                    <a v-if="getAuthUser.subscriptions">
                                        <li @click="customerPortal()">Membership</li>
                                    </a>
                                    <RouterLink to="/setting">
                                        <li @click="checkToggle">Settings</li>
                                    </RouterLink>
                                    <a data-toggle="modal" data-target="#contactModal">
                                        <li @click="checkToggle">Contact</li>
                                    </a>
                                    <a href="javascript:void(0)" @click="logout">
                                        <li @click="checkToggle">Logout</li>
                                    </a>
                                </ul>
                            </div>
                            <div class="writeButton fourth-column" v-if="!isLogin">
                                <div class="d-flex justify-content-end align-items-center column-gap-10">
                                    <button
                                            class="btn btn-write shadow-none float-right"
                                            id="customize_button"
                                            data-toggle="modal"
                                            data-target="#LoginModal">
                                        <span class="sign-in-text-color">Sign In </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>

        <div class="home-page-wrapper">
            <div class="container">
                <RouterView/>
            </div>
        </div>
        <LoginModal v-if="isLoginIn" @emitCloseModal="emitCloseModal"/>
        <WalletModal/>
        <ContactModal/>

        <div class="full-search-page-wrapper">
            <div class="full-search-page w-100">
                <div class="d-flex align-items-center w-100">
                    <i class="fas fa-arrow-left" @click="removeFullSearchPage"></i>
                    <search-field></search-field>
                </div>
            </div>
        </div>
        <div v-if="!isLogin">
        <cookie-law>
            <div slot-scope="props" class="position-relative w-100">
                <div class="d-block text-center d-md-flex align-items-sm-center align-items-center sloat-scope justify-content-center">
                    <p class="mb-0 text-center mr-md-4  mr-sm-2 mr-2 d-inline">
                        By using our website you agree to our <a href="/privacypolicy"><u>Cookie Policy</u></a>
                    </p>
                    <button class="skew btn btn-outline-dark got-it-btn" @click="props.accept"><span>Got it</span></button>
                </div>
            </div>
        </cookie-law>
        </div>
    </div>
</template>

<script>
    import SearchField from "../components/SearchField/SearchField";
    import $ from "jquery";
    // import TopDots from "../components/TopDots/TopDots";
    import LoginModal from "../components/Modals/LoginModal";
    import WalletModal from "../components/Modals/WalletModal";
    import ClickOutside from "vue-click-outside";
    import ContactModal from "../components/Modals/ContactModal";
    import CookieLaw from 'vue-cookie-law'

    import {mapGetters} from "vuex";
    // import helpers from "../helpers";

    export default {
        name: "AppLayoutLinks",
        components: {
            SearchField,
            // TopDots,
            LoginModal,
            WalletModal,
            ContactModal,
            CookieLaw
        },
        data() {
            return {
                isLoginIn: true,
                isToggle: false,
                isSearch: false,
            };
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: "auth/getAuthUser",
                isNotify:"article/getSuggestionNotification",
            }),
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            customerPortal(){
                this.$store.dispatch("auth/stripeCustomerPortal").then((response)=>{
                    console.log("testing",response);
                    window.location.href = response.data.url;
                });
            },
            checkNotifications(){
                this.$store.dispatch("article/readSuggestionNotesForUser");
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            stripeConnect(){
                this.$store.dispatch("auth/stripeOnboardingProcess").then((response)=>{
                    console.log("testing",response);
                    window.location.href = response.data.url;
                });
            },
            stripeAccountLogin(){
                this.$store.dispatch("auth/stripeAccountLogin").then((response)=>{
                    console.log("testing",response);
                    window.location.href = response.data.url;
                });
            },
            hide() {
                this.isToggle = false;
            },
            emitCloseModal(bool) {
                console.log(bool);
                this.isLoginIn = bool;
            },
            logout() {
                this.$store.dispatch("auth/routeLogout").then(() => {
                    window.location = "/home";
                });
            },
            addArticle() {
                this.$store.dispatch('article/addNewArticle').then(
                    this.$router.push({name: 'writeArticle'})
                );
                // localStorage.removeItem("cardData");
                // localStorage.setItem("isEdit",JSON.stringify(false));
            },
            showFullSearch() {
                $(".full-search-page").css("display", "block");
                $(".inputField").focus();
            },
            removeFullSearchPage() {
                $(".full-search-page").css("display", "none");
            },
            profile() {
                this.$store.dispatch('auth/updateUserProfileDetail', this.getAuthUser).then(() => {
                    this.$router.push({name: 'userProfile', params: {slug: this.getAuthUser.slug}})
                })
            },
        },
        mounted() {
            this.checkNotifications();
            setInterval(function () {
                $('#bellIcon').toggle();
            },400);
        },
        // do not forget this section
        directives: {
            ClickOutside,
        },

    };
</script>

<style>
a {
        text-decoration: none !important;
    }

    .cursor {
        cursor: pointer;
    }

    header {
        width: 100%;
        margin: 0 auto 0px;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .top-main {
        flex: 0 0 100%;
        border-bottom: 4px solid black;
        padding-bottom: 5px;
    }

    .first-row {
        align-items: end;
    }

    .header a.router-link-exact-active {
        color: #42b983;
    }


    .profile-wrapper img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }

    .column-gap-10 {
        column-gap: 10px;
    }

    .sign-in-text-color {
        color: #007bff;
    }

    .custom-dropdown-menu {
        position: absolute;
        top: 66px;
        padding: 0;
        z-index: 1000;
        margin-bottom: 0;
        font-size: 0.9rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        right: 0;
        min-width: 200px;
    }

    .custom-dropdown-menu li {
        padding: 0.25rem 1.5rem;
        font-weight: 400;
        color: #212529;
    }

    .custom-dropdown-menu li:hover {
        background-color: #ddd;
    }

    .userName h5 {
        font-size: 16px;
        color: #000000;
        display: inline-block;
        font-family: "Merriweather" !important;
        font-weight: 400 !important;
    }

    li a {
        text-decoration: none;
        font-size: 14.44px;
        font-weight: normal !important;
        color: #000000 !important;
        width: 100%;
        display: inline-block;
    }

    .wallet-amount {
        color: gray;
        display: inline-block;
    }

    #list {
        position: absolute;
        top: 48px;
        list-style: none;
        background-color: #ffffff;
        left: 0;
        padding: 10px 20px !important;
        z-index: 1;
        width: 125px;
        border-radius: 10px;
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    }
    #input,#search_button{
    background-color: transparent !important;
    }
    
    .input {
        border-radius: 0;
        border: 3px solid black;
        border-bottom: 5px solid black;
        height: 30px;
    }

    .searchfield {
        position: relative;
        /* margin-left: 50px; */
    }

    .dot {
        text-align: center;
    }

    .first-column {
        flex: 0 0 18%;
        line-height: 1;
    }

    .first-column a {
        display: inline-block;
        height: 39px;
    }

    .second-column {
        flex: 0 0 25%;
        line-height: 1;
    }

    .third-column {
        flex: 0 0 15%;
    }

    .fourth-column {
        flex: 0 0 40%;
    }

    .not-logged-in .first-column {
        flex: 0 0 18%;
    }

    .not-logged-in .second-column {
        flex: 0 0 50%;
    }

    .not-logged-in .third-column {
        flex: 0 0 3%;
    }

    .not-logged-in .fourth-column {
        flex: 0 0 22%;
    }

    .nav-edit-icon {
        color: gray;
        font-size: 23px;
    }

    .full-search-page {
        display: none;
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1035;
        padding: 0 1rem;
    }

    .full-search-page .d-flex {
        background-color: #ffffff;
        padding: 1rem;
    }

    .full-search-page .fa-arrow-left {
        color: #000000;
        font-size: 20px;
        display: inline-block;
        margin-right: 15px;
    }

    .full-search-page .d-flex > div {
        width: 100%;
    }

    .full-search-page #input {
        display: block;
        width: 100%;
        height: 39px !important;
        margin-right: 5px;
        border: 2px solid #000000;
    }
    .Cookie--base{
        padding: 15px !important;
        background-color: #ffffff;
        box-shadow: 0 10px 24px 0 rgb(54 61 77 / 15%);
    }
    .Cookie Cookie--bottom Cookie--base{
        box-sizing: border-box;
        box-shadow: 0 10px 24px 0 rgb(54 61 77 / 15%)!important;
        font-family: Sora, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 1.55555556;
        border-radius: 0;
        padding: 14px 50px!important;
        color: black;

    }
    .sloat-scope p{
        font-size: 18px;
        font-weight: 600;
    }  
    .sloat-scope p a{
        color:black;
    }
    /*-- .Cookie--base .got-it-btn{
        position: absolute;
        right: 0;
    } --*/
    .Cookie--base .got-it-btn span {
        font-size: 16px;
        font-weight: 600;

    }
</style>
