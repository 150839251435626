<template>
    <div class="home-blog">
        <div class="row">
            <div class="main-home main-home-fixed col-lg-8 col-md-12 col-sm-12 order-xxl-first order-lg-first order-sm-last order-last" id="mainCard">
                <div v-if="(getType==='home' || getType==='customHomePage') && activeCategory.id!==0">
                    <Subscriber :isLogin="isLogin" :category="activeCategory" :subscriber="activeCategory.category_name"
                                :total="activeCategory.subscribers+' subscribers '"/>
                </div>
                <SubHeading category="Breaking" hashTag="" link="/breaking"/>
                <br/>
                <div v-for="(breakingData,index) in categoryArticle.breaking" :key="breakingData.id+'_'+Math.random()">
                    <BlogCard :cardData="breakingData" :isEdit="false" @emitSkimOpenModel="emitSkimOpenModel"/>
                    <hr v-if="checkCardLine(index , categoryArticle.breaking.length)" class="hr-progressbar">
                </div>
                <br v-if="categoryArticle.breaking.length===0"/>

                <div v-if="getType==='customHomePage'">
                    <SubHeading category="Subscribed" hashTag="" link="/subscribed"/>
                    <br/>
                    <div v-for="(subscribedData ,index) in categoryArticle.subscribed"
                         :key="subscribedData.id+'_'+Math.random()">
                        <BlogCard :cardData="subscribedData" :isEdit="false" @emitSkimOpenModel="emitSkimOpenModel"/>
                        <hr v-if="checkCardLine(index , categoryArticle.subscribed.length)" class="hr-progressbar">
                    </div>
                </div>
                <br/>
                <SubHeading v-if="categoryArticle.upcoming.length >0" category="Upcoming" hashTag="" link="/upcoming"/>
                <div v-for="(upcomingData,index) in categoryArticle.upcoming" :key="upcomingData.id+'_'+Math.random()">
                    <BlogCard :cardData="upcomingData" :isEdit="isEditCheck(upcomingData)" :isUpcoming="true" :isPublished="false"/>
                    <hr v-if="checkCardLine(index , categoryArticle.upcoming.length)" class="hr-progressbar">
                </div>
                <br v-if="categoryArticle.upcoming.length===0"/>

                <div v-for="article in categoryArticle.articles" :key="article.id+'_'+Math.random()">
                    <br/>
                    <SubHeading
                            :category="article.hash_tag_name"
                            :hashTag="article.category.category_name"
                            :link="'/hashtag/'+article.slug"
                            :categoryActive="article.category"
                            :hashTagData="article"
                            :isHashTagData="true"
                            @emitActiveCategory="emitActiveCategory"
                    />
                    <div class="d-flex" style="justify-content:space-between">
                        <div class="count-story d-inline-block">
                            <p class="stories">{{article.stories}} Stories</p>
                        </div>
                        <div class="hash-tag d-inline-block d-none d-md-none d-sm-block">
                            <div class="hashtag ml-3 ml-sm-4">
                                <p @click="activeCategoryParent(article.category)" class="mb-0 cursor text-dark">
                                    {{article.category.category_name}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-for="(cardData,index) in uniqueArticleCategories(article.article_category)"
                         :key="cardData.id+'_'+Math.random()">
                        <div v-if="cardData.article && index<4">
                            <BlogCard :cardData="cardData.article" :isEdit="false"
                                      @emitSkimOpenModel="emitSkimOpenModel"/>
                        </div>
                        <hr v-if="checkCardLine(index,article.article_category.length)" class="hr-progressbar">
                    </div>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getArticles"
                ></infinite-loading>
            </div>
            <div :style="' left:'+mainHome+'px'"
                 class="menu-home col-lg-4 col-m-12 col-sm-12 order-xxl-last order-lg-last order-sm-first order-first"
                 id="scrollY">
                <div class="bottomScroll scrollid" id="isScrollId">
                    <CountrySelect :isCustom="getType" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                    <br>
                    <BlogCategory :type="getType" @emitActiveCategory="emitActiveCategory"/>
                </div>
            </div>
            
        </div>
        <CommentReply v-if="isLogin"/>
        <SkimModal type="homeBlog" :isSkim="isSkim" :article="cardData" :isLogin="isLogin"
                   :getAuthUser="getAuthUser" @emitIsSkimData="emitIsSkimData"
                   @emitCloseModel="emitCloseModel"/>
    </div>
</template>

<script>

    import BlogCard from "../components/BlogCard/BlogCard";
    import BlogCategory from "../components/BlogCategory/BlogCategory";
    import CountrySelect from "../components/CountrySelect/CountrySelect";
    import SubHeading from "../components/Headings/SubHeading";
    import CommentReply from "../components/HomeComment/CommnetReply";
    import InfiniteLoading from "vue-infinite-loading";
    import Subscriber from "../components/Subcribers/Subscriber";
    import SkimModal from "../components/Modals/SkimModal";
    // eslint-disable-next-line no-unused-vars
    import helpers from '../helpers';

    import $ from 'jquery';
    import {mapGetters} from "vuex";

    export default {
        name: "HomeBlog",
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Home'
        },
        components: {
            BlogCard,
            BlogCategory,
            CountrySelect,
            SubHeading,
            CommentReply,
            InfiniteLoading,
            Subscriber,
            SkimModal,
        },

        data() {
            return {
                windowWidth: 0,
                mainHome: 0,
                outerHeight: 0,
                previousScroll: 0,
                isSkim: false,
                cardData: {
                    id: 0,
                    slug: "",
                    total_percentage_skim: 0,
                    user:{
                        id:0,
                        slug:"",
                        profile_pic:"",
                        sec_1: '',
                        name: '',
                        total_user_subscribers: 0,
                        is_subscribe:false,
                        description: '',
                        social_user: {
                            fb_url: '',
                            twitter_url: '',
                            youtube_url: ''
                        },
                    }
                },
                skimData: [],
                // category: {
                //     "id": 0,
                //     "user_id": 0,
                //     "country_id": 0,
                //     "category_name": "home",
                //     "level": 0
                // },
                delta: 5,
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                subscribed: "article/getSubscribedArticle",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                categoryArticle: "article/getCategoryArticle",
                pageNumber: "article/getPageNumber",
                activeCategory: "category/getActiveCategory",
                getType: "category/getType",
            }),
        },

        created() {
            this.checkPageType();
            document.body.style = ''
        },
        methods: {
            getTrendingHashtag() {
                let payload = {
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType
                }
                this.$store.dispatch('category/getTrendingHashTags', payload);
            },
            emitActiveCategory(category) {
                this.emptyTrendingHashtag();
                this.$store.dispatch("article/setCategoryPageNumber", 1).then(() => {
                    this.getCategoryBreakingAndUpComing(category);
                    this.getArticles();
                    this.getTrendingHashtag();
                });
            },
            isEditCheck(upcomingData){
                if(this.getAuthUser !== undefined){
                    if(this.getAuthUser.id !== undefined){
                        return upcomingData.user.id===this.getAuthUser.id
                    }
                    return false;
                }
                return false
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.getType
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            activeCategoryParent(category) {
                this.$store.dispatch("category/updateActiveCategory", category).then(() => {
                    this.emitActiveCategory(category);
                })

            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            emptyTrendingHashtag() {
                this.$store.dispatch("category/emptyTrendingHashtag");
            },
            updateCountyId(id) {
                this.emptyCategories();
                this.emptyTrendingHashtag();
                this.selectedCategories(id);
                this.$store.dispatch("article/setCategoryPageNumber", 1);
                let homeCategory = {
                    id: 0,
                    "user_id": 0,
                    "country_id": id,
                    "category_name": "home",
                    "subscribers": 0,
                    "level": 0
                };
                this.$store.dispatch("category/updateActiveCategory", homeCategory).then(() => {
                    this.getCategoryBreakingAndUpComing(this.activeCategory);
                    this.getArticles();
                    this.getTrendingHashtag();
                });
            },
            checkPageType() {
                if (this.$route.name === 'customHomePage') {
                    this.$store.dispatch('category/updateType', 'customHomePage')
                } else {
                    this.$store.dispatch('category/updateType', 'home')
                }
            },
            getCategoryBreakingAndUpComing(category) {
                let payload = {
                    categoryId: category.id,
                    countryId: this.defaultCountry.id,
                    type: this.getType
                };
                this.$store.dispatch(
                    "article/getCategoryBreakingAndUpComingArticle",
                    payload
                );
            },
            getArticles($state) {
                let payload = {
                    page: this.categoryArticle.page,
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType,
                    $state: $state,
                };
                this.$store.dispatch("article/getCategoryArticle", payload);
            },
            calculate() {
                this.windowWidth = window.innerWidth;
                let divWidth = $(".main-home");
                if (divWidth.offset()) {
                    this.mainHome = divWidth.offset().left + divWidth.width() + 55;
                }
            },
            emitSkimOpenModel(article) {
                this.isSkim = true;
                this.cardData = article;
                $('#skim-modal').modal('show');
            },
            emitCloseModel() {
                $('#skim-modal').modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data) {
                this.cardData.total_views_skim = data.total_views_skim
            },
            uniqueArticleCategories(articleCategories) {
                var unique = [];
                var distinct = [];
                if (articleCategories) {
                    for (let i = 0; i < articleCategories.length; i++) {
                        if (articleCategories[i].article !== undefined) {
                            if (articleCategories[i].article.id !== undefined) {
                                if (!unique[articleCategories[i].article.id]) {
                                    distinct.push(articleCategories[i]);
                                    unique[articleCategories[i].article.id] = 1;
                                }
                            }
                        }
                    }
                }
                return distinct
            },
            checkCardLine(index, total) {
                return helpers.checkCardLine(index, total);
            },
        },
        mounted() {
            this.emptyCategories();
            this.emptyTrendingHashtag();
            this.selectedCategories(this.defaultCountry.id);
            this.$store.dispatch("article/setCategoryPageNumber", 1);
            this.$store.dispatch("article/getCommentCustomTags");
            this.getCategoryBreakingAndUpComing(this.activeCategory);
            this.getTrendingHashtag();
            this.calculate();
            helpers.fixedSideBarCategory(this, 'scrollid', 'nav-container', 'isScrollId')
            // this.fixedSideBarCategory()

            setInterval(() => {
                this.calculate();
            }, 100);

        },

    }
</script>

<style>
    .stories {
        font-size: 15px;

    }

</style>