<template>
    <div id="articleDetail">
        <Default v-if="articleDetail.layout==='default'" :upcomingArticle="getCurrentUrl()"
                 :articleDetail="articleDetail"/>
        <Hide v-if="articleDetail.layout ==='hide'" :upcomingArticle="getCurrentUrl()" :articleDetail="articleDetail"/>
        <ArticleWidth v-if="articleDetail.layout==='article_width'" :upcomingArticle="getCurrentUrl()"
                      :articleDetail="articleDetail"/>
        <Banner v-if="articleDetail.layout==='banner'" :upcomingArticle="getCurrentUrl()"
                :articleDetail="articleDetail"/>
        <OverSized v-if="articleDetail.layout==='oversized'" :upcomingArticle="getCurrentUrl()"
                   :articleDetail="articleDetail"/>
        <div class="row skim-row">
            <div :class="layoutDetailPageClass()">
                <div v-if="getCurrentUrl()!=='UpcomingDetail'" class="">
                    <div class="skim-share-note mt-lg-0 mt-md-3 mt-sm-0">
                        <!-- audio start -->
                        <div v-if="articleDetail.audio"
                             class="main-audio order-md-0 order-sm-0 order-1 cursor d-flex large-audio w-100"
                             style="column-gap: 10px;">
                            <div class="show-audio" v-show="isToggle">
                                <audio controls controlsList="nodownload nofullscreen" class="audio-file">
                                    <source :src="articleDetail.audio ? articleDetail.audio :''" type="audio/ogg">
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        </div>

                        <!-- audio end -->
                        <div class="d-flex order-md-1 order-sm-1 order-0" style="column-gap: 10px;align-item:center;">
                            <div>
                                <a v-if="!this.getAuthUser.subscriptions" @click="checkUserSubscription()" class="shareText font-weight-bold" style="color:grey; font-size: 14px">Join</a>
                                <a v-else class="shareText font-weight-bold" style="color:grey; font-size: 14px">Joined</a>
                            </div>
                            <span @click="isToggle =!isToggle">
                                <volumeSvg  class="cursor" v-if="articleDetail.audio"/>
                            </span>
                            <div v-if="checkIfExistSkim()" id="skimToogle" class="d-flex">
                                <b class="font-weight-bold pr-2">Skim</b>
                                <b-form-checkbox @change="highlightedText" name="check-button" switch>
                                </b-form-checkbox>
                            </div>

                            <!-- <i v-if="articleDetail.audio" @click="isToggle =!isToggle" style="font-size: 17px;color:grey;"
                               class="fa fa-volume-up pt-1 pl-1 cursor"></i> -->

                            <!--                            <img v-if="articleDetail.audio" @click="isToggle =!isToggle"-->
                            <!--                                 src="../assets/images/volume.png" class="mt-1" alt="volume" width="18" height="18"/>-->
                            <div v-if="articleDetail.notes.length>0" class="pr-1">
                                <a @click="checkNotesForSubscriptionUsers()" data-toggle="modal" class="d-flex mt-1"
                                   style="color:grey;align-items: baseline;">
                                    <i style="font-size: 17px" class="far fa-file-alt pl-1"></i>
                                </a>
                            </div>

                            <div class="share-icons">
                                <a href="javascript:void(0)">
                                    <label class="mb-0 shareText font-weight-bold" style="color:grey; font-size: 14px">Share:</label>
                                </a>
                                <span class="heading-icons">
                                    <a @click="shareFacebookArticle(articleDetail)" class=" d-inline-block  " target="_blank">
                                        <img src="../assets/images/facebook.png" height="17px" alt="no-image">
                                    </a>
                                    <a :href="shareTwitterArticle(articleDetail)" target="_blank"
                                       class=" d-inline-block">
                                        <img src="../assets/images/twitter.png" height="17px" alt="no-image">
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div id="skimContent" class="blog-detail mt-2 mb-2">
                            <span></span>
                        </div>
                    </div>
                </div>
                <div class="article">
                    <div v-if="getCurrentUrl()==='Details'" class="article-content"
                         v-html="updateNoteArticle">
                    </div>
                    <div v-if="getCurrentUrl()==='UpcomingDetail'" class="article-content mt-2">
                        {{articleDetail.upcoming_content}}
                    </div>
                    <div v-if="getCurrentUrl()==='UpcomingDetail'">
                        <div class="mt-2">
                            <SubmitHeadingRequest :isLogin="isLogin" :getAuthUser="getAuthUser" :articleDetail="articleDetail"
                                                  @emitSubmitHeadingRequest="emitSubmitHeadingRequest"/>
                        </div>
                        <span v-if="(articleDetail.is_heading && articleDetail.article_suggestions.length >0) && this.$route.name === 'UpcomingDetail'" v-once class="badge badge-pill badge-secondary" style="font-size:10px">Heading & Body</span>
                        <div v-for="suggestions in articleDetail.article_suggestions"
                             :key="suggestions.id+'_'+Math.random()">
                            <HeadingRequestList :suggestion="suggestions" :getAuthUser="getAuthUser"/>
                        </div>
                    </div>

                    <div>

                    </div>

                    <div v-if="getCurrentUrl()==='Details'" class="divider">
                        <hr class="line mx-auto" style="margin-top: 65px"/>
                    </div>
                    <br/>
                    <div v-if="getCurrentUrl()==='Details'" class="row">
                        <div class="col-sm-12 col-12">
                            <div v-for="article_suggestion in articleDetail.article_suggestions"
                                 :key="article_suggestion.id+'_'+Math.random()" class="col.sm.12">
                                <SelectedSuggestionDetails
                                        v-if="article_suggestion.selected_suggestion.status && checkSelectedSuggestion(article_suggestion)"
                                        :articleSuggestion="article_suggestion"/>
                                <hr v-if="article_suggestion.selected_suggestion.status && checkSelectedSuggestion(article_suggestion)"
                                    class="line mx-auto"
                                    style="margin-top: 65px"/>
                            </div>
                        </div>
                    </div>
                    <ActiveMembersList v-if="getCurrentUrl()==='Details' && articleDetail.writer_subscribers.length>0" :members="articleDetail.writer_subscribers"  />
                    <div v-if="getCurrentUrl()==='Details'" class="col-12 col-lg-12 col-md-8 pt-4 mx-auto">
                        <div class="top-nav">
                            <nav>
                                <ul class="nav">
                                    <li class="nav-item ml-2" v-for="articleCategory in uniqueCategories"
                                        :key="articleCategory.id+'_'+Math.random()">
                                        <router-link to="/home" class="nav-link"
                                                     @click.native="activeClass(articleCategory.category)">
                                            {{articleCategory.category.category_name}}
                                        </router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <br/>
                        <div class="space">
                            <ul class="nav">
                                <li class="nav-item" v-for="articleCategory in uniqueHashtags"
                                    :key="articleCategory.id+'_'+Math.random()">
                                    <router-link :to="'/hashtag/'+articleCategory.hashtag.slug"
                                                 @click.native="activeClass(articleCategory.category)"
                                                 class="nav-link space-nav-link">
                                        {{articleCategory.hashtag.hash_tag_name}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div v-if="articleId && getCurrentUrl() !== 'UpcomingDetail'">
            <UserCommentsBox :articleId="articleId" :customTags="articleDetail.custom_tags"/>
        </div>
        <ImageCaptionModal/>
        <ShowNotesModal v-if="getCurrentUrl() !== 'UpcomingDetail'" :notes="articleDetail.notes"/>
        <UniqueNoteModal/>
        <PricingTableModal/>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Default from "../components/customizeLayout/Default";
    import ArticleWidth from "../components/customizeLayout/ArticleWidth";
    import Banner from "../components/customizeLayout/Banner";
    import Hide from "../components/customizeLayout/Hide";
    import OverSized from "../components/customizeLayout/Oversized";
    import ImageCaptionModal from "../components/Modals/ImageCaptionModal";
    import SubmitHeadingRequest from "../components/HeadingRequest/SubmitHeadingRequest.vue";
    import HeadingRequestList from "../components/HeadingRequest/HeadingRequestList";
    import ShowNotesModal from "../components/Modals/ShowNotesModal";
    import UniqueNoteModal from "../components/Modals/UniqueNoteModal";
    import SelectedSuggestionDetails from "../components/ArticleDetail/SelectedSuggestionDetail";
    import $ from "jquery";
    import UserCommentsBox from "../components/UserComments/UserCommentsBox";
    import volumeSvg from '../components/SVG/VolumeSvg';
    import PricingTableModal from "../components/Modals/PricingTableModal";


    import helpers from "../helpers";
    import ActiveMembersList  from "../components/ActiveMembersList/ActiveMembersList.vue";

    export default {
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Article Detail'
        },
        components: {
            PricingTableModal,
            OverSized,
            Default,
            UserCommentsBox,
            ArticleWidth,
            Banner,
            Hide,
            ImageCaptionModal,
            SubmitHeadingRequest,
            HeadingRequestList,
            ShowNotesModal,
            UniqueNoteModal,
            SelectedSuggestionDetails,
            volumeSvg,
            ActiveMembersList
        },
        data() {
            return {
                checked: false,
                isCount: false,
                previousScroll: 0,
                count: 0,
                articleCount: 1,
                isToggle: false,
                isScroll: false,
                suggestions: [],
                h1FontFamily: "Oswald!important",
                h2FontFamily: "Oswald!important",
                h5FontFamily: "Oswald!important",

                blockquoteFontFamily: "Oswald!important",
                articleId: localStorage.getItem('articleId') ? parseInt(localStorage.getItem('articleId')) : null
            }
        },
        computed: {
            ...mapGetters({
                articleDetail: 'article/getArticleDetails',
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
            uniqueCategories: function () {
                var unique = [];
                var distinct = [];
                if (this.articleDetail.article_category) {
                    for (let i = 0; i < this.articleDetail.article_category.length; i++) {
                        if (this.articleDetail.article_category[i].category !== undefined) {
                            if (this.articleDetail.article_category[i].category.category_name_slug !== undefined) {
                                if (!unique[this.articleDetail.article_category[i].category.category_name_slug]) {
                                    distinct.push(this.articleDetail.article_category[i]);
                                    unique[this.articleDetail.article_category[i].category.category_name_slug] = 1;
                                }
                            }
                        }
                    }
                }
                return distinct
            },
            uniqueHashtags: function () {
                var unique = [];
                var distinct = [];
                if (this.articleDetail.article_category) {
                    for (let i = 0; i < this.articleDetail.article_category.length; i++) {
                        if (this.articleDetail.article_category[i].hashtag !== null) {
                            if (this.articleDetail.article_category[i].hashtag.hash_tag_name_slug !== undefined) {
                                if (!unique[this.articleDetail.article_category[i].hashtag.hash_tag_name_slug]) {
                                    distinct.push(this.articleDetail.article_category[i]);
                                    unique[this.articleDetail.article_category[i].hashtag.hash_tag_name_slug] = 1;
                                }
                            }
                        }
                    }
                }
                return distinct
            },
            updateNoteArticle: function () {
                let content = this.articleDetail.content;
                this.articleDetail.notes.map(x => {
                    content = content.replace(x.uuid, `<p><NOTES id="${x.uuid}" data-wow="${x.title}"  style="font-size: 17px;z-index: 2" class="cursor far fa-file-alt pl-1 copy-note"></NOTES></p>`);
                });
                this.articleDetail.article_suggestions.map(x => {
                            let html=this.selectedSuggestionHTML(x);
                            content = content.replace(x.uuid,html);
                });
                if (content) {
                    if (this.articleDetail.font_family) {
                        content = content.replaceAll('<h1>', '<h1 style="font-family:  ' + this.articleDetail.font_family.h1 + '!important; color: ' + this.articleDetail.font_family.h1_color + '!important;">')
                        content = content.replaceAll('<h2>', '<h2 style="font-family:  ' + this.articleDetail.font_family.h2 + '!important; color: ' + this.articleDetail.font_family.h2_color + '!important;">')
                        content = content.replaceAll('<h5>', '<h5 style="font-family:  ' + this.articleDetail.font_family.pt + '!important; color: ' + this.articleDetail.font_family.pt_color + '!important;">')
                        content = content.replaceAll('<blockquote>', '<blockquote style="font-family:  ' + this.articleDetail.font_family.blockquote + '!important; color: ' + this.articleDetail.font_family.blockquote_color + '!important;">')
                    }
                }
                return content;
            }
        },
        created() {

        },
        methods: {
            checkUserSubscription(){
                if (this.articleDetail.user.stripe_connect) {
                    if (!this.getAuthUser.subscriptions) {
                        return $('#exampleModal').modal('show');
                    }
                }
            },
            selectedSuggestionHTML(x) {
                let content = '';
                let collapseIcon;
                let collapseData;
                let headingStyle;
                let contentStyle;
                let headingAndBody;
                let endBody;
                let fontSize;
                let collapseHeadningAndBody;
                if (x.content !== "" && x.content !== null) {
                    content = x.content
                }else{
                    if(x.selected_suggestion.content) {
                        content = x.selected_suggestion.content
                    }
                }
                if(x.is_collapse){
                    collapseIcon='fa fa-angle-double-down float-right';
                    collapseData='suggestion-para collapse set-heading-body';
                    headingStyle='padding:8px 10px;width:100%;background-color: #f6f6f6;color:#000000 !important;font-family:Merriweather !important';
                    contentStyle='font-size: 18px;line-height: 2;font-weight: 500;padding: 5px !important; border: 1px solid lightgrey !important';
                    headingAndBody= "";
                    endBody= "";
                    fontSize="font-size:10px;display:none";
                    collapseHeadningAndBody="Heading & Body";

                }else{
                    collapseIcon='fa fa-angle-double-down d-none';
                    collapseData='suggestion-para set-heading-body';
                    headingStyle='padding:8px 10px;width:100%;color:#000000 !important;font-family:Merriweather !important;pointer-events:none';
                    contentStyle='font-size: 18px;line-height:2;font-weight: 500;padding: 5px !important';
                    headingAndBody= "Heading & Body";
                    endBody= "End Body";
                    fontSize="font-size:10px";
                    collapseHeadningAndBody="";
                }
                return `<div class="selected-suggestion-details">
                                        <div class="selected-main">
                                            <div class="suggestion-detail d-flex pb-3">
                                                <div class="suggestion-profile">
                                                    <img src="${x.user.profile_pic}" alt="profile=img" class="suggestion-img rounded-circle blog-img mr-2"/>
                                                    <a href="javascript:void(0)" target="_top" class="suggestion-user cursor">
                                                    <label class="mb-0 mr-1 suggestion-user-name">${x.user.name}</label>
                                                    </a>
                                                </div>
                                                <div class="suggestion-endorsements">
                                                    <label class="suggestion-label mb-0 float-right" data-toggle="modal" data-target="${x.endorsement_count !== 0 ? '#endorsementModal_' + x.id : ''}" style="cursor: pointer;">Endorsements: ${x.endorsement_count} </label>
                                                </div>
                                            </div>
                                               <div class="suggestion-content cursor">
                                               <p  class="badge badge-pill badge-secondary" style="font-size:10px">${collapseHeadningAndBody}</p>
                                                <h2 data-toggle="collapse" data-target="${'#collapseTwo' + x.id}" aria-controls="${'collapseTwo' + x.id}" style="${headingStyle}" class="first-heading d-inline-block mr-2 mb-0">${x.suggestion}<i  aria-expanded="true" class="${collapseIcon}" style="font-size: 14px;margin-top: 10px;" aria-hidden="true"></i></h2>
                                                <span class="badge badge-pill badge-secondary" style=${fontSize}>${headingAndBody}</span>
                                                <div id="${'collapseTwo' + x.id}" class="${collapseData}" style="${contentStyle}">${content}</div>
<!--                                                <div id="${'collapseTwo' + x.id}" class="${collapseData}" style="${contentStyle}" v-html=${content} </div>-->
                                                <span class="badge badge-pill badge-secondary" style=${fontSize}>${endBody}</span>
                                            </div>
                                        </div>
                                  </div>
                <div class="EndorsementModal">
                <div class="modal fade" id="${'endorsementModal_' + x.id}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header pb-0 endorsemenHeader">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body pt-0">
                ` + this.endorsementHtml(x) + `
                </div>
                </div>
                </div>
                </div>
                </div>`;
            },
            endorsementHtml(x) {
                let html = '';
                for (var i = 0; i < x.endorsement.length; i++) {
                    if (x.endorsement[i].status) {
                        let image = '../assets/images/no-image.png';
                        if (x.endorsement[i].user.profile_pic) {
                            image = x.endorsement[i].user.profile_pic
                        }
                        html += `<div class="pb-2">
                    <a class="cursor request-img" href="user/profile/${x.endorsement[i].user.slug}" target="_top" data-dismiss="modal" aria-label="Close">
                    <img src="${image}" class="rounded-circle profileImg" alt="userImage">
                    <label class="mb-0 mr-1 profile-name cursor pl-3">${x.endorsement[i].user.name}</label>
                </a>
                </div>`;
                    }
                }
                return html;
            },
            setHeadingBody(content,id){
                $('#'+id).html(content);
            },
            getCurrentUrl() {
                return this.$route.name
            },
            getArticleDetail() {
                let payload = {
                    slug: localStorage.getItem('articleSlug'),
                }
                if (payload.slug) {
                    document.body.style = '';
                    this.$store.dispatch('article/articleDetail', payload).then((response) => {
                        if (response.data.bg_color) {
                            document.body.style = response.data.bg_color.replace(/[\r\n]/gm, '');
                        }
                        this.showSkim()
                        this.imageCaptionShow();
                        this.articleId = response.data.id
                        $('.collapse').removeClass('show');
                        if (response.data.status.id === 7 && this.getCurrentUrl() === 'UpcomingDetail') {
                            this.$router.push({
                                name: 'Details',
                                params: {slug: response.data.slug, articleId: response.data.id}
                            })
                        }
                    })
                }
            },
            showSkim() {
                var text = $('.article-content skim');
                if (text.length > 0) {
                    $('.skimButton').removeClass('d-none');
                }
            },
            checkSelectedSuggestion(articleSuggestion) {
                if(articleSuggestion.selected_suggestion !== null){
                    if(articleSuggestion.selected_suggestion.status){
                        return !this.articleDetail.content.includes(articleSuggestion.uuid)
                    }else{
                        return false;
                    }
                }
            },
            checkIfExistSkim() {
                return $('.article-content skim').length > 0;
            },
            highlightedText() {
                var text = $('.article-content skim');
                var skim = $('#skimContent span');
                if (skim.hasClass('highLight')) {
                    skim.removeClass('highLight');
                    skim.removeAttr("style");
                    skim.empty();
                } else {
                    for (var i = 0; i < text.length; i++) {
                        skim.append("<li>" + text[i].innerText + "</li>");
                        skim.addClass('highLight');
                    }
                }
            },
            getArticleViews() {
                window.onscroll = () => {
                    this.isScroll = true;
                }
            },
            countViews() {
                if (this.count >= 0 && this.count <= 15) {
                    this.count++;
                }
                if (this.count === 15) {
                    this.isCount = true;
                }
                if (this.count === 16 && this.isCount && this.isScroll) {
                    let payload = {
                        slug: this.articleDetail.slug
                    }
                    this.$store.dispatch('article/addArticleViews', payload).then(() => {
                        this.isCount = false
                    })
                    this.count++;
                }
            },
            addCountFifteenSecond() {
                setInterval(() => {
                    this.countViews();
                }, 1000)
                return this.count;
            },
            layoutDetailPageClass() {
                if (this.articleDetail.layout === 'default') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-12 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3   skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'hide') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3    skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'article_width') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3 mt-3  skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'banner') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'oversized') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3   skimButton  blog-detail';
                }
            },
            activeClass(category) {
                this.$store.dispatch("category/updateActiveCategory", category);
            },
            shareFacebookArticle(article) {
                let url = this.$sharePathUpcoming + article.slug
                /*global FB*/
                FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.likes',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': url,
                                'og:title': article.title,
                                'og:description': article.description,
                                'og:image': article.featured_image
                            }
                        })
                    },
                    function () {
                    });

            },
            shareTwitterArticle(article) {
                let url = this.$sharePathUpcoming + article.slug
                return 'https://twitter.com/intent/tweet?text=' + url
            },
            imageCaptionShow() {
                this.articleDetail.captions.map(x => {
                    let checkImageCaptionId = $("#" + x.image_id);
                    if (checkImageCaptionId.length > 0) {
                        let id = checkImageCaptionId.attr("id");
                        let captionId = "captions" + id;
                        let width = checkImageCaptionId.width();

                        let float = checkImageCaptionId.css('float');
                        checkImageCaptionId.wrapAll(`<div class="arrticleWrapper" />`);
                        let div = `<figcaption id=${captionId} style="margin:10px auto 0; padding: 0 16px; text-align: center;display:block;">${x.caption}</figcaption>`;
                        checkImageCaptionId.parent('.arrticleWrapper').css('width', width)
                        checkImageCaptionId.parent('.arrticleWrapper').css('margin', 'auto')
                        checkImageCaptionId.parent('.arrticleWrapper').append(div)
                        checkImageCaptionId.parent('.arrticleWrapper').parent().css('clear', 'both')
                        checkImageCaptionId.css('width', '100%')
                        if (width <= 185) {
                            console.log("200", width)
                            $("#" + captionId).css({
                                'font-size': '14px',
                                'clear': 'both',
                                'font-family': 'Roboto',
                                'line-height': '1.4',
                            })
                        }
                        if (width >= 185 && width <= 350) {
                            console.log("350", width)
                            $("#" + captionId).css({
                                'font-size': '14px',
                                'clear': 'both',
                                'font-family': 'Roboto',
                                'line-height': '1.4'
                            })
                        }
                        if (width >= 350 && width <= 600) {
                            console.log("600", width)

                            $("#" + captionId).css({
                                'font-size': '14px',
                                'clear': 'both',
                                'font-family': 'Roboto',
                                'line-height': '1.4'
                            })
                        }
                        if (width >= 600 && width <= 720) {
                            console.log("720", width)

                            $("#" + captionId).css({
                                'font-size': '14px',
                                'clear': 'both',
                                'font-family': 'Roboto',
                                'line-height': '1.4'
                            })
                        }
                        // console.log("float", float)
                        if (float === 'right' || float === 'left') {
                            // checkImageCaptionId.css({"float":"none"})
                            checkImageCaptionId.parent('.arrticleWrapper').css('float', float)
                        }
                    }
                });
            },
            emitSubmitHeadingRequest(headingRequest) {
                let payload = {
                    suggestion: headingRequest.heading_request,
                    content: headingRequest.heading_content,
                    article_id: this.articleId
                }
                this.$store.dispatch("article/submitHeadingRequest", payload);
            },

            checkNotesForSubscriptionUsers(){
                const hasPaidNote = this.articleDetail.notes.some(note => note.is_paid === 1);
                const hasStripeConnect = this.articleDetail.user.stripe_connect;
                const hasSubscriptions = this.getAuthUser.subscriptions;

                if (hasPaidNote) {
                    if (hasStripeConnect && !hasSubscriptions) {
                        $('#exampleModal').modal('show');
                        return;
                    }
                } else {
                    $('#showNotes-modal').modal('show');
                }
            }
        },
        mounted() {
            if (this.$route.params.slug) {
                localStorage.setItem('articleId', this.$route.params.articleId)
                localStorage.setItem('articleSlug', this.$route.params.slug)
            }
            this.getArticleDetail();
            this.getArticleViews();
            this.$store.dispatch("article/getCommentCustomTags");
            this.addCountFifteenSecond()
            helpers.fixedSideBarCategory(this, 'scrollNav', 'nav-container', '', 2)
            document.body.addEventListener('click', (event) => {
                if (event.target.tagName === 'COLLAPSE') {
                    if ($(event.target).children('i').hasClass('fa-angle-double-down')) {
                        $(event.target).children('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
                    } else {
                        $(event.target).children('i').addClass('fa-angle-double-down').removeClass('fa-angle-double-up')
                    }
                }
                if (event.target.tagName === 'h2') {
                    if ($(event.target).children('i').hasClass('fa-angle-double-down')) {
                        $(event.target).children('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
                    } else {
                        $(event.target).children('i').addClass('fa-angle-double-down').removeClass('fa-angle-double-up')
                    }
                }
                if (event.target.tagName === 'I') {
                    if ($(event.target).hasClass('fa-angle-double-down')) {
                        $(event.target).removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
                    } else {
                        $(event.target).addClass('fa-angle-double-down').removeClass('fa-angle-double-up')
                    }
                }


                if (event.target.tagName === 'NOTES') {
                    if (event.target.id) {
                        let index = this.articleDetail.notes.findIndex(x => x.uuid === event.target.id);
                        if (index !== -1) {
                            if(this.articleDetail.notes[index].is_paid && !this.getAuthUser.subscriptions){
                                $('#exampleModal').modal('show');
                            }else{
                                $('#uniqueNote').modal('show');
                                $("#show-note").text(this.articleDetail.notes[index].title)
                            }
                        }
                    }
                }
                if (event.target.tagName === 'IMG') {
                    if (event.target.id) {
                        $("#ImageCaptionModal").modal('show');
                        // let captionsLink = $("#captionsLink");
                        $("#captionsImage").attr('src', event.target.src)
                        $("#captionsText").text($("#captions" + event.target.id).text())
                        // captionsLink.attr("href", event.target.src)
                        // captionsLink.text(event.target.src)
                        return;
                    }
                }
            });

        },


    };
</script>

<style scoped>
    /*-- detail page--*/
    h1 {
        font-family: v-bind(h1FontFamily);
        color: red;
    }

    h2 {
        font-family: v-bind(h2FontFamily);
        color: red;
    }

    h5 {
        font-family: v-bind(h5FontFamily);
        color: red;
    }

    blockquote {
        font-family: v-bind(blockquoteFontFamily);
        color: red;
    }

    .skim-row {
        margin-top: 40px;
    }

    .article {
        margin-bottom: 100px;
        font-size: 18px;
        line-height: 2;
        font-weight: 500;
    }

    .skim-share-note {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
    }

    .divider .line {
        border-top: 1px solid black !important;
        width: 20% !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .top-nav .nav .nav-item .nav-link {
        color: grey !important;
        font-size: 18px;
        font-family: Roboto;
    }

    .nav-link {
        padding: 0px;
    }

    .article .space {
        display: flex;
        justify-self: flex-start;
        border-bottom: 2px solid black;
    }

    .space a {
        font-size: 18px;
        margin: 0 5px 10px;
    }

    .space a:hover {
        color: black;
    }

    .divider {
        margin-left: 10px;
        clear: both;
    }

    .space .space-nav-link {
        color: #007bff !important;
        font-family: Roboto;
    }

    .skimButton #skimToogle b {
        font-size: 14px;
        color: grey;
    }

    #skimContent span li {
        font-size: 18px;
        padding-left: 15px;
    }

    .share-icons {
        display: flex;
        column-gap: 2px;
    }

    .share-icons a {
        vertical-align: text-bottom;
    }


    .show-audio .audio-file {
        width: 100%;
    }

    .show-audio {
        width: 100%;;
    }

    .audio-file {
        height: 25px;
    }
</style>