<template>
    <div class="insert-notes">
        <!-- Modal -->
        <div class="modal fade pr-0" id="noteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg  modal-dialog-sm-centered mobile-model-width" role="document">
                <div class="modal-content insert-content-modal" style="overflow-y:auto;">
                    <div class="modal-header">
                        <h4 class="modal-title nodeTitle" id="exampleModalLabel">Insert Notes</h4>
                        <button @click="clickButtonToCloseModal('tweetModal')" type="button" class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
                        <div class="modal-body" style="overflow-y:auto;max-height:450px">
                            <div v-if="getAuthUser.stripe_connect !== null" class="d-flex pb-2 mt-lg-2 mt-md-2 " style="column-gap: 20px;">
                                <div class="form-check">
                                    <input v-model="isPaid" class="form-check-input" :value="0" type="radio" name="flexRadio"
                                           id="flexRadio">
                                    <label class="form-check-label" for="flexRadio">
                                        Free
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="isPaid" class="form-check-input" :value="1" type="radio" name="flexRadio"
                                           id="flexRadio2">
                                    <label class="form-check-label" for="flexRadio2">
                                        Members Only
                                    </label>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <textarea v-model="title" maxlength="1000"
                                          class="note form-control textarea"></textarea>
                                <span v-if="isValidate" class="pl-1 text-danger">{{ message }}</span>
                                <div class="d-flex">
                                    <div class="text" style="flex:0 0 80%;">
                                        Paste Notes Tags at the start of desired paragraphs. Notes Icon will be displayed where the tags were added after the article gets published.
                                    </div>
                                    <div class=" characters" style="flex:0 0 20%;text-align:end">
                                        (Min: {{title.length}} Max: 1000)
                                        <button type="submit" class="tweetBtn createBtn btn-secondary text-muted">{{ isEdit ?
                                            'Update' : 'Create' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                           
                            <ul class="note-list pl-0 pt-3">
                                <li class="note-item" v-for="note in articleDetail.notes"
                                    :key="note.id+'_'+Math.random()"
                                    style="list-style:none;">
                                    <i @click="editNote(note)" class="fa-solid fa-pen-to-square pr-1"
                                       style="cursor: pointer"></i>
                                    <!-- <i @click="deleteNote(note)" class="fa fa-trash pr-1 text-danger" aria-hidden="true"
                                       style="cursor: pointer"></i> -->
                                    <div class="main-list d-inline" @click="copyClipboard(note)">
                                        <strong class="list-id" title="Copy Command to Clipboard">{{note.uuid}}</strong>
                                        <span class="copy-icon ml-2"><i class="far fa-copy"></i></span>
                                    </div>
                                    <span class="d-block">  {{note.title}} </span>
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer" style="padding-inline:16px;">

                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import {mapGetters} from 'vuex';
    import helpers from "../../helpers";
    import Helper from "../../helper";

    export default {
        name: "TweetModal",
        data() {
            return {
                title: "",
                isValidate: false,
                isEdit: false,
                isPaid:'',
                noteUuid: "",
                showNotes: [{
                    id: 0,
                    title: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."
                }],
                message: ''
            }
        },
        computed: {
            ...mapGetters({
                getAuthUser: 'auth/getAuthUser',
                articleDetail: "article/getArticleDetails",
            })
        },
        methods: {
            clickButtonToCloseModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if(this.getAuthUser.stripe_connect && (this.isPaid === undefined || this.isPaid === '')){
                            return Helper.toast(this, 'Alert', 'Please select Free OR Member only first', 'danger')
                        }
                        if (this.title !== "") {
                            this.isValidate = false;
                            let payload = {
                                article_id: this.articleDetail.id,
                                title: this.title,
                                uuid: this.noteUuid,
                                is_paid:this.isPaid
                            }
                            let api = null;
                            if (this.isEdit) {
                                api = this.$store.dispatch("article/updateNotes", payload)
                            } else {
                                api = this.$store.dispatch("article/addNotes", payload)
                            }
                            api.then(response => {
                                helpers.successMessages(this, response)
                            }).catch(error => {
                                helpers.errorMessage(this, error)
                            });
                            this.title = ""
                            this.noteUuid = ""
                            this.isEdit = false
                            this.isPaid=""
                        } else {
                            this.isValidate = true;
                            this.message = 'Please enter note!'
                        }
                    }
                });

            },
            editNote(note) {
                this.isEdit = true;
                this.noteUuid = note.uuid;
                this.title = note.title
            },
            deleteNote(payload) {
                this.title = ""
                this.noteUuid = ""
                this.isEdit = false
                this.$store.dispatch("article/deleteNotes", payload).then(response => {
                    helpers.successMessages(this, response)
                }).catch(error => {
                    helpers.errorMessage(this, error)
                });
            },
            copyClipboard(note) {
                navigator.clipboard.writeText(note.uuid);
                return Helper.toast(this, 'Copy', 'Copy command to clipboard', 'success')
            }
        },
        watch: {}
    }
</script>

<style scoped>
  
    .modal-footer {
        padding-top: 0;
    }

    .modal-body {
        padding-bottom: 0;
    }

    .insert-notes .textarea {
        word-break: break-word;
        resize: none;
    }

    .nodeTitle {
        font-size: 1.35rem;
        font-weight: 500;
    }

    .note {
        height: 150px;
    }

    .createBtn {
        float: right;
        background-color: #f5f5f5 !important;
        border: 1px solid #007bff !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        border-radius: 0.25rem
    }

    .note-list {
        clear: both;
    }

    .note-list .note-item {
        word-break: break-word;
    }

    .main-list {
        display: flex;
    }

    .list-id {
        font-weight: bold;
        color: black;
        cursor: pointer;
    }

    .copy-icon {
        display: none;
    }

    .copy-icon .copyIcon {
        color: #00C642;
    }

    .main-list:hover .copy-icon {
        display: inline-block !important;

    }
</style>