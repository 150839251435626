<template>
    <div>
        Login
    </div>
</template>
<script>
    import Helper from "../helper";
    import helpers from "../helpers";

    export default {
        data(){
            return {}
        },
        created() {
            document.body.style = ''
        },
        methods:{
            testingUserLogin(id){
                let payload={};
                if(id === undefined || id ===1){
                     payload = {
                        email: 'arslan@gmail.com',
                        name: 'Arslan',
                        avatar: 'http://theworded.mashghol.com/img/no-image.9ce6cc12.png',
                        token: '1sjzoidawieiqwoafhaidhcawiodhqwfihaweduasijhfddziu',
                        social_id: '1sjzoidawieiqwoafhaidhcawiodhqwfihaweduasijhfddziu',
                        social_type: 'google',
                    }
                }
                if(id===2){
                     payload = {
                        email: 'aliraza@yopmail.com',
                        name: 'User2',
                        avatar: 'http://theworded.mashghol.com/img/no-image.9ce6cc12.png',
                        token: '2dfdfdfdfsjzoiwoafhaidhcawiodhqwfihaweduasijhfddziu',
                        social_id: '2dfdfdfdfsjzoiwoafhaidhcawiodhqwfihaweduasijhfddziu',
                        social_type: 'google',
                    }
                }

                if(id ===3){
                     payload = {
                        email: 'testUser3@yopmail.com',
                        name: 'User3',
                        avatar: 'http://theworded.mashghol.com/img/no-image.9ce6cc12.png',
                        token: '3dsjzcdcluxzkcjxdvlxdkjucdoifusdoifdsudosidaiosuds',
                        social_id: '3dsjzcdcluxzkcjxdvlxdkjucdoifusdoifdsudosidaiosuds',
                        social_type: 'google',
                    }
                }

                if(id>3){
                     payload = {
                        email: 'testUser4@yopmail.com',
                        name: 'User4',
                        avatar: 'http://theworded.mashghol.com/img/no-image.9ce6cc12.png',
                        token: '4dfdsfdsfddsdfdsasdsdfdszfdssddz',
                        social_id: '4dfdsfdsfddsdfdsasdsdfdszfdssddz',
                        social_type: 'google',
                    }
                }

                this.$store.dispatch('auth/login', payload).then(()=>{
                    window.location = '/home';
                }).catch(error => {
                    helpers.errorMessage(this, error);
                });
            }
        },
        mounted() {
            console.log("check params",this.$route.params.id);
            const userArray = [1,2,3,4];
            // console.log("check bool params",userArray.includes(parseInt(this.$route.params.id)));
            if(userArray.includes(parseInt(this.$route.params.id))){
                this.testingUserLogin(parseInt(this.$route.params.id));
            }else{
                return  Helper.toast(this, 'Alert', 'Invalid User!', 'danger')
            }
        }
    }

</script>