<template>
  <div class="pricing-table-modal">
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
               <div class="demo10">
                <div class="container">
                    
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="pricingTable10">
                                <div class="pricingTable-header">
                                    <h3 class="heading">Tier 1</h3>
                                    <span class="price-value">
                                        <span class="currency">$</span> 35
                                        <span class="month">/mo</span>
                                    </span>
                                </div>
                                <div class="pricing-content">
                                    <ul>
<!--                                        <li>10 Subdomains</li>-->
<!--                                        <li>15 Domains</li>-->
                                    </ul>
                                    <a @click="subscribe('price_1MvyHeAxlHJgfQPb1H2FnGob')" class="read">Subscribe</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="pricingTable10">
                                <div class="pricingTable-header">
                                    <h3 class="heading">Tier 2</h3>
                                    <span class="price-value">
                                        <span class="currency">$</span> 50
                                        <span class="month">/mo</span>
                                    </span>
                                </div>
                                <div class="pricing-content">
                                    <ul>
<!--                                        <li>15 Subdomains</li>-->
<!--                                        <li>20 Domains</li>-->
                                    </ul>
                                    <a @click="subscribe('price_1MvyJDAxlHJgfQPbrRLdZa49')" class="read">Subscribe</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
        </div>
      </div>
    </div>


  </div>
</template>

<script>
    import $ from "jquery";
    import {mapGetters} from "vuex";

    export default {
        name: "pricingTableModal",
        data(){
            return{
                plan_id:'',
            }
        },
        components:{

        },
        computed:{
            ...mapGetters({
                articleDetail: 'article/getArticleDetails',
            }),
        },
        methods:{
            subscribe(planId){
                let payload = {
                    writer_slug: this.articleDetail.user.slug,
                    plan_id: planId
                }
                this.$store.dispatch('auth/userStripeSessionCheckout',payload).then((response)=>{
                    window.location.href = response.data.url;
                });
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
        }
    }
</script>

<style>
.demo10{background:#C0BFBF;padding:30px 0}
.pricingTable10{text-align:center}
.pricingTable10 .pricingTable-header{padding:30px 0;background:#4d4d4d;position:relative;transition:all .3s ease 0s}
.pricingTable10:hover .pricingTable-header{background:#09b2c6}
.pricingTable10 .pricingTable-header:after,.pricingTable10 .pricingTable-header:before{content:"";width:16px;height:16px;border-radius:50%;border:1px solid #d9d9d8;position:absolute;bottom:12px}
.pricingTable10 .pricingTable-header:before{left:40px}
.pricingTable10 .pricingTable-header:after{right:40px}
.pricingTable10 .heading{font-size:20px;color:#fff;text-transform:uppercase;letter-spacing:2px;margin-top:0}
.pricingTable10 .price-value{display:inline-block;position:relative;font-size:55px;font-weight:700;color:#09b1c5;transition:all .3s ease 0s}
.pricingTable10:hover .price-value{color:#fff}
.pricingTable10 .currency{font-size:30px;font-weight:700;position:absolute;top:6px;left:-19px}
.pricingTable10 .month{font-size:16px;color:#fff;position:absolute;bottom:15px;right:-30px;text-transform:uppercase}
.pricingTable10 .pricing-content{padding-top:50px;background:#fff;position:relative}
.pricingTable10 .pricing-content:after,.pricingTable10 .pricing-content:before{content:"";width:16px;height:16px;border-radius:50%;border:1px solid #7c7c7c;position:absolute;top:12px}
.pricingTable10 .pricing-content:before{left:40px}
.pricingTable10 .pricing-content:after{right:40px}
.pricingTable10 .pricing-content ul{padding:0 20px;margin:0;list-style:none}
.pricingTable10 .pricing-content ul:after,.pricingTable10 .pricing-content ul:before{content:"";width:8px;height:46px;border-radius:3px;background:linear-gradient(to bottom,#818282 50%,#727373 50%);position:absolute;top:-22px;z-index:1;box-shadow:0 0 5px #707070;transition:all .3s ease 0s}
.pricingTable10:hover .pricing-content ul:after,.pricingTable10:hover .pricing-content ul:before{background:linear-gradient(to bottom,#40c4db 50%,#34bacc 50%)}
.pricingTable10 .pricing-content ul:before{left:44px}
.pricingTable10 .pricing-content ul:after{right:44px}
.pricingTable10 .pricing-content ul li{font-size:15px;font-weight:700;color:#777473;padding:10px 0;border-bottom:1px solid #d9d9d8}
.pricingTable10 .pricing-content ul li:last-child{border-bottom:none}
.pricingTable10 .read{display:inline-block;font-size:16px;color:#fff;text-transform:uppercase;background:#d9d9d8;padding:8px 25px;margin:30px 0;transition:all .3s ease 0s}
.pricingTable10 .read:hover{text-decoration:none}
.pricingTable10:hover .read{background:#09b1c5}
@media screen and (max-width:990px){.pricingTable10{margin-bottom:25px}
}
/* Credit to https://bootsnipp.com/snippets/92erW */
</style>