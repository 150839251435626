<template>
    <div></div>
</template>

<script>

    export default {
        name: 'Membership',
        data(){
            return {
                pricingTableId:"prctbl_1MwJAeKbSArPgwsJLnkNqitK",
                publishableKey:"pk_test_51Cndr6KbSArPgwsJYkMH5NLPBxL69hAvMv8NMYVTU1u5w5AXx5K0Z6xkZSMFlb16CfJgy6WOcxP32yAGXncW2bzl00xbLamnzo"
            }
        },

        mounted() {
            console.log("testing price table view");
        }
    };
</script>