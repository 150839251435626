<template>
    <div class="commentRadio d-flex">
    <div class="d-sm-flex show-small-screen">
        <div class="group group-mobile">

            <div class="custom-tag-label" v-for="customTag in customTags" :key="customTag.name">
                <input v-if="!customTag.deleted_at" v-model="commentTagSelected" type="radio" v-bind:value="customTag.name" :id="customTag.name+isModal"
                       class="custom-tag"/>
                <label v-if="!customTag.deleted_at" :for="customTag.name+isModal" class="radioLabel custom-radio-label supportive-label badge-pill text-capitalize">
                    <span>
                        <small class="text-capitalize"><img v-if="emoji" :src="emoji" height="15" width="20"></small>
                        <small class="text-capitalize pl-2">{{customTag.name}}</small>
                    </span>
                </label>
            </div>

                <div v-for="customId in customIds" :key="customId.name + '-' + Math.random()">
                    <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="customId.name"
                        :id="customId.name + isModal" :class="showTagClass(customId.name)" />
                    <label :for="customId.name + isModal" :class="'radioLabel ' + showTagClass(customId.name) + '-label badge-pill'">
                        <span><small class="text-capitalize">{{ customId.name }}</small></span>
                    </label>
                </div>
        </div>
    </div>
       
    </div>
</template>

<script>
    export default {
        name: "RadioButton",
        props: {
            commentTag: String,
            isModal: Boolean,
            customIds:Array,
            customTags:Array,
        },
        data() {
            return {
                commentTagSelected: "",
                colorCode : "",
                emoji:""
            }
        },

        mounted() {
            this.root = document.documentElement
            this.commentTagSelected = this.commentTag;

        },
        methods:{
            showTagClass(tag){
                if(tag === 'supportive'){
                    return 'supportive';
                }
                if(tag === 'neutral'){
                    return 'neutral';
                }
                if(tag === 'critique'){
                    return 'critique';
                }
            },

        },
        watch: {
            commentTag() {
                this.commentTagSelected = this.commentTag
            },
            commentTagSelected(val) {
                if (!val) return
                let index = this.customTags.findIndex(x=>x.name === val);
                if(index !== -1){
                    this.colorCode = this.customTags[index].color;
                    this.emoji = this.customTags[index].emoji;
                    this.root.style.setProperty('--tag-color',this.colorCode);
                    this.root.style.setProperty('--tag-border',this.colorCode);
                }
                if (this.isModal === true) {
                    this.$emit('emitCommentTagModal', val)
                }
                else {
                    this.$emit('emitCommentTag', val)
                }

            }
        }
    }
</script>

<style scoped>
    /*:root {*/
    /*    --tag-color: rgb(215, 189, 255);*/
    /*    --tag-border:1px solid rgb(215, 189, 255);*/
    /*}*/
.commentRadio .group {
        display: flex;
        
        align-items: center;
        margin-bottom:-7px;
    }
    .commentRadio .group-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }


    .commentRadio input[type=radio] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .commentRadio label {
        position: relative;
        margin-right: 1em;
        padding-left: 14px;
        line-height: 1;
        cursor: pointer;
        color: #6c757d;
        font-size: 10px;
        padding-right: 5px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

    .commentRadio label:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 8px;
        left: 1px;
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid #17a2b8;
        border-radius: 18px;
    }
    .commentRadio label:after {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 0;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #17a2b8;
        border-radius: 18px;
        opacity: 0;
    }

    .commentRadio input[type=radio] + label::before {
        border-radius: 1em;
    }

    /* Checked */

    .commentRadio input[type=radio]:checked + label {
        padding-left: 1em;
        color:white;
    }
 /*.commentRadio .supportive:checked + label::before,*/
 /* .commentRadio .neutral:checked + label::before,*/
 /*  .commentRadio .critique:checked + label::before {*/
 /*        width: 100%;*/
 /*       z-index: auto;*/
 /*       left: 0;*/
 /*       top: 0;*/
 /*    border-radius: 30px;*/
 /*  }*/
    /*.commentRadio .custom-tag-label .custom-tag:checked + label::before {*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*    z-index: auto;*/
    /*    left: 0;*/
    /*    top: 0;*/
    /*    border-radius: 30px;*/
    /*}*/
 /*-- .commentRadio label.supportive-label{
        background-color: #2ecc71;
        color:white;
    }
     .commentRadio label.neutral-label{
       background: lightgray;
    }
     .commentRadio label.status-label{
        background: #ff5e57;
        color:white;
    } --*/

    .commentRadio .custom-tag-label .custom-tag:checked + label::after {
        background-color: var(--tag-color);
        border: 1px solid var(--tag-border);
        opacity: 1;
    }
    .commentRadio .supportive:checked + label::after {
        background: #27ae60;
        border: 1px solid #27ae60;
        opacity: 1;
    }

    .commentRadio .neutral:checked + label::after {
        background:darkgray;
        border: 1px solid darkgray;
        opacity: 1;
    }

    .commentRadio .critique:checked + label::after {
        background: #dc3545;
        border: 1px solid #dc3545;
        opacity: 1;
      
    }
  .commentRadio input[type=radio]:checked + label {
    padding-inline: 10px !important;
    color: #fff;
}
    .commentRadio label,
    .commentRadio label::before {
        transition: 0.25s all ease;
    }

    .commentRadio small {
        position: relative;
        top: 0;
        z-index: 1;
        font-size: 15px;
    }
    .customTag{
        display:flex!important;
    }

    .commentRadio label span {
        padding-left: 3px;
    }
</style>