import axios from "../../axios.js";

export default {
    async login({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/auth/login', payload).then((response) => {
                commit('SET_AUTH_USER', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async logout({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/auth/logout', payload).then((response) => {
                commit('LOGOUT_AUTH_USER')
                resolve(response.data)
            }).catch((error) => {
                commit('LOGOUT_AUTH_USER')
                reject(error)
            })
        })
    },
    async routeLogout({commit}) {
        commit('LOGOUT_AUTH_USER')
    },

    async updateUserProfileDetail({commit},user) {
        commit('UPDATE_USER_PROFILE_DETAIL',user)
    },


    async facebookLogin({commit}, token) {
        return new Promise((resolve, reject) => {
            axios.get(`https://graph.facebook.com/v8.0/me?access_token=${token}`).then((response) => {
                console.log("commit", commit)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateUserProfile({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/profile', payload).then((response) => {
                commit('UPDATE_USER_PROFILE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateUserSubscriber({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/subscriber', payload).then((response) => {
                commit('UPDATE_PROFILE_USER_SUBSCRIBER', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getAllUsers({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/admin/users').then((response) => {
                commit('GET_ALL_USERS',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async stripeOnboardingProcess({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/stripe/account/connect').then((response) => {
                console.log("test commit",commit);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async stripeCustomerPortal({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/stripe/customer/portal/session').then((response) => {
                console.log("test commit",commit);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async stripeAccountLogin({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/stripe/account/login/link').then((response) => {
                console.log("test commit",commit);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateUserIsBanned({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/banned', payload).then((response) => {
                commit('UPDATE_USER_IS_BANNED',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async headingRequestPermission({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/heading/permission', payload).then((response) => {
                commit('HEADING_REQUEST_PERMISSION',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateUserMonetizeStatus({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/user/monetize', payload).then((response) => {
                commit('UPDATE_USER_MONETIZE_STATUS',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async checkAdminPassword({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/login', payload).then((response) => {
                commit('ADMIN_LOGIN',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async userStripeSessionCheckout({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/stripe/create/checkout/session', payload).then((response) => {
                console.log("test",commit);
                console.log("test data",response);
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async saveStripeSubscriptionCheckout({commit},payload) {
        return new Promise((resolve, reject) => {
            axios.get('/stripe/success/' + payload.session_id + '/' + payload.writer_slug).then((response) => {
                commit('UPDATE_USER_SUBSCRIPTIONS',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}
