<template>
    <div>
        <section class="active-member">
        <h3>Active Members</h3>

        <div data-v-3417ebcc="" class="read-more mb-12 d-block"><input data-v-3417ebcc="" id="read-more-checkbox11" type="checkbox" aria-hidden="true" name="status" class="read-more__checkbox">
             <div data-v-3417ebcc="" class="read-more__text mb-2 right-text ml-2 active-member-list" style="margin-top: -3px;">
                    <div v-for="member in members" class="active-user d-inline-block mt-2" :key="member.id">
                    <img :src="member.profile_pic" alt="Profile image" class="m-auto d-block"/>
                    <p class="mt-2">{{member.name}}</p>
                    </div>
             </div>

        <label v-if="members.length >3" data-v-3417ebcc="" for="read-more-checkbox11" data-read-more="View All Members" data-read-less="See less" aria-hidden="true" class="read-more__label ml-3"></label></div>

        </section>
    </div>
</template>

<script>
export default {
name : "ActiveMembersList",
    data(){
        return{

        }
    },
    props:{
     members:Array
    }
}
</script>

<style scoped>
.active-member{
    padding:20px 10px;
    width: 100%;
    background-color: #f6f6f6;
    color: #000;
}
.active-member h3{
        font-family: Oswald !important;
    color: rgb(0, 0, 0) !important;

}
.active-member-list{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.active-user{
    display: inline-block;
    margin-right: 20px;
}
.active-user img{
    width:50px;
    height: 50px;
    border-radius: 50%;
}
.active-user p{
    font-size: 14px;
}
.member-btn{
    width: 125px;
    font-size: 12px;
}
.read-more{
    display: -webkit-box;
    --line-clamp: 1!important;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.read-more__checkbox ~ .read-more__label::before {
    content: attr(data-read-more);
}
</style>