export default {
  allUsers:[],
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {id:""},
  isUserLogin : !!localStorage.getItem('user'),
  token: localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') :'',
  profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : {
    sec_1: '',
    profile_pic:'',
    name: '',
    total_user_subscribers: 0,
    description: '',
    social_user: {
      fb_url: '',
      twitter_url: '',
      youtube_url: ''
    }
  },


}
